import React, { useState, useEffect } from "react";

// @import styles
import {
  HomeContainer,
  HomeMain,
  FaqPartOut,
  FaqPart,
  FaqSub,
  FaqTitle,
  FaqMain,
  FaqItem,
  FItemTop,
  FMainContent,
  FItemContent,
  FaqMainLeft,
  ProductPart,
  ProductTitle,
  ProductContent,
  ProductItems,
  ProductItem,
  ProductItemContent,
  LearnMoreBtn,
  CarPartOut,
  CarImg,
  CarPart,
  CarContent,
  CarBtns,
  CarLearnBtn,
  CarStartBrn,
  CarPartC,
  LogPartOut,
  LogPart,
  LogSub,
  LogTitle,
  LogmarginDiv,
  FleetPart,
  FleetLeft,
  FTitle,
  FContent,
  FSubWrapper,
  FBtn,
  FleetRight,
  SupportPart,
  SupportImg,
  SupportRight,
  Stitle,
  Scontent,
  Slists,
  STop,
  SItem,
  SCircle,
  SItemContent,
  TextMargin,
  SupportDownPart,
  SupportDownRight,
  StitleDown,
  ScontentDown,
  SlistsDown,
  STopDown,
  SItemDown,
  SItemDownContent,
  SupportDownImg,
  CarTitle,
} from "./service.style";
import { BsArrowRight } from "react-icons/bs";
import { StrapiAPI } from "../../actions/config";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import axios from "axios";
// @import resource

const ELDComponent = () => {
  const [selectFAQ, setSelectFAQ] = useState(-1);
  const [strapiData, setStrapiData] = useState([]);
  const [homeData, setHomeData] = useState([]);
  const [commonData, setCommonData] = useState([]);

  const fetchData = () => {
    axios.get(`${StrapiAPI}/serivces`).then((awako) => {
      if (awako) {
        setStrapiData(awako?.data);
      }
    });
    axios.get(`${StrapiAPI}/homes`).then((awako) => {
      if (awako) {
        setHomeData(awako?.data);
      }
    });
    axios.get(`${StrapiAPI}/commons`).then((awako) => {
      if (awako) {
        setCommonData(awako?.data);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const changeFAQ = (e) => {
    if (e == selectFAQ) setSelectFAQ(-1);
    else setSelectFAQ(e);
  };
  return (
    <HomeContainer>
      <HomeMain>
        <FleetPart>
          <FleetLeft>
            <FSubWrapper>OUR SERVICES</FSubWrapper>
            <FTitle>
              <span>Professional Administrative</span> Services to Keep Your
              Fleet Running
            </FTitle>
            <FContent>
              You struggle to find team members to run; we have dedicated team
              members to provide high-quality service to increase your
              productivity and drive business growth.
            </FContent>
            <FBtn>
              <span>Get started now</span>
              <BsArrowRight className="arrow-right-class" />
            </FBtn>
          </FleetLeft>
          <FleetRight
            src={
              strapiData &&
              StrapiAPI +
                strapiData.filter((e) => e.name == "service-pro")[0]?.media?.url
            }
          />
        </FleetPart>
        <LogPartOut>
          <LogPart>
            <LogSub>Our Services</LogSub>
            <LogTitle>
              What We <span>Offer</span>
            </LogTitle>
            <SupportPart>
              <SupportImg
                src={
                  strapiData &&
                  StrapiAPI +
                    strapiData.filter((e) => e.name == "service-offer")[0]
                      ?.media?.url
                }
              />
              <SupportRight>
                <Stitle>Safety and Compliance Audit Support</Stitle>
                <Scontent>
                  Outsourcing your safety and compliance to Awako will ensure
                  that your drivers stay up to date on their FMCSA requirements.
                  Awako will perform all ELD and DVIR to make sure that it’s
                  performed properly and can notify you immediately of any
                  problems.
                </Scontent>
                <Slists>
                  <STop>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/tmsSteps/search.png" alt="" />
                      </SCircle>
                      <SItemContent>Mock terminal inspections</SItemContent>
                    </SItem>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/tmsSteps/ico2.png" alt="" />
                      </SCircle>
                      <SItemContent w="214px">HOS logs audit</SItemContent>
                    </SItem>
                  </STop>
                  <STop>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/eldIco/note.png" alt="" />
                      </SCircle>
                      <SItemContent>DOT & CHP audit preparations</SItemContent>
                    </SItem>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/eldIco/chart.png" alt="" />
                      </SCircle>
                      <SItemContent w="214px">
                        Equipment maintenance evaluations
                      </SItemContent>
                    </SItem>
                  </STop>
                  <STop>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/eldIco/filter.png" alt="" />
                      </SCircle>
                      <SItemContent>Drug and Alcohol programs</SItemContent>
                    </SItem>
                  </STop>
                </Slists>
              </SupportRight>
            </SupportPart>
            <LogmarginDiv />
            <SupportDownPart>
              <SupportDownRight>
                <StitleDown>Administrative support</StitleDown>
                <ScontentDown>
                  Awako properly performs EDL and DVIR for your drivers to
                  ensure complete FMCSA compliance. With many former FMCSA
                  employees, we are well-versed in the appropriate requirements
                  and offer top-of-the-line audit support.
                </ScontentDown>
                <SlistsDown>
                  <STopDown>
                    <SItemDown>
                      <SCircle>
                        <img src="/assets/image/eldIco/edit.png" alt="" />
                      </SCircle>
                      <SItemDownContent>Data entry services</SItemDownContent>
                    </SItemDown>
                    <SItemDown>
                      <SCircle>
                        <img src="/assets/image/eldIco/plant.png" alt="" />
                      </SCircle>
                      <SItemDownContent w="214px">
                        Lumper issue
                      </SItemDownContent>
                    </SItemDown>
                  </STopDown>
                  <STopDown>
                    <SItemDown>
                      <SCircle>
                        <img src="/assets/image/eldIco/phone.png" alt="" />
                      </SCircle>
                      <SItemDownContent>
                        Driver & customer check calls
                      </SItemDownContent>
                    </SItemDown>
                    <SItemDown>
                      <SCircle>
                        <img src="/assets/image/platIcon6.png" alt="" />
                      </SCircle>
                      <SItemDownContent w="214px">
                        Sending invoices to customer
                      </SItemDownContent>
                    </SItemDown>
                  </STopDown>
                  <STopDown>
                    <SItemDown>
                      <SCircle>
                        <img src="/assets/image/eldIco/plus.png" alt="" />
                      </SCircle>
                      <SItemDownContent>
                        Collecting the BOL/POD documents from drivers and
                        carriers
                      </SItemDownContent>
                    </SItemDown>
                    <SItemDown>
                      <SCircle>
                        <img src="/assets/image/eldIco/opennote.png" alt="" />
                      </SCircle>
                      <SItemDownContent>
                        Account receivables follow up
                      </SItemDownContent>
                    </SItemDown>
                  </STopDown>
                </SlistsDown>
              </SupportDownRight>
              <SupportDownImg
                src={
                  strapiData &&
                  StrapiAPI +
                    strapiData.filter((e) => e.name == "service-support")[0]
                      ?.media?.url
                }
              />
            </SupportDownPart>
          </LogPart>
        </LogPartOut>
        <FaqPartOut>
          <ProductPart>
            <ProductTitle>
              Product <span>Snippets</span>
            </ProductTitle>
            <ProductContent>
              Our Powerful Solutions to Help You Manage Your Fleet
            </ProductContent>
            <ProductItems>
              <ProductItem>
                <img
                  src={
                    homeData &&
                    StrapiAPI +
                      homeData.filter((e) => e.name == "tms")[0]?.media?.url
                  }
                  alt=""
                />
                <ProductItemContent>
                  <span>TMS</span>An all-in-one platform powered by AI that
                  helps you manage your all of your business in one
                  user-friendly and powerful solution.
                  <LearnMoreBtn>
                    Learn more <BsArrowRight />
                  </LearnMoreBtn>
                </ProductItemContent>
              </ProductItem>
              <ProductItem>
                <img
                  src={
                    homeData &&
                    StrapiAPI +
                      homeData.filter((e) => e.name == "eld")[0]?.media?.url
                  }
                  alt=""
                />
                <ProductItemContent>
                  <span>ELD</span>Advanced electronic logging devices  to help
                  automate and ensure compliance with  FMCSA and DOT
                  regulations. Available in both Bluetooth and Hard-wired
                  versions.
                  <LearnMoreBtn>
                    Learn more <BsArrowRight />
                  </LearnMoreBtn>
                </ProductItemContent>
              </ProductItem>
              <ProductItem>
                <img
                  src={
                    homeData &&
                    StrapiAPI +
                      homeData.filter((e) => e.name == "dashcam")[0]?.media?.url
                  }
                  alt=""
                />
                <ProductItemContent>
                  <span>DashCam</span>High-quality onboard dash cameras provides
                  exceptional video recording and utilizes vehicle telematics to
                  provide a variety of safety and economic data and real-time
                  alerts to both drivers and management.
                  <LearnMoreBtn>
                    Learn more <BsArrowRight />
                  </LearnMoreBtn>
                </ProductItemContent>
              </ProductItem>
            </ProductItems>
          </ProductPart>
        </FaqPartOut>
        <CarPartOut>
          <CarPartC>
            <CarImg
              src={
                commonData &&
                StrapiAPI +
                  commonData.filter((e) => e.name == "trans")[0]?.media?.url
              }
              alt=""
              draggable={false}
            />
            <CarPart>
              <CarTitle>
                Taking Your Fleet Into the <span>Future</span>
              </CarTitle>
              <CarContent>
                Transition your business to experience the true power of a
                single platform to manage your entire operation and let Awako
                take your company into the future of fleet management.
              </CarContent>
              <CarBtns>
                <CarLearnBtn>Learn more</CarLearnBtn>
                <CarStartBrn>
                  Get started now <BsArrowRight className="car-start-btn" />
                </CarStartBrn>
              </CarBtns>
            </CarPart>
          </CarPartC>
        </CarPartOut>
      </HomeMain>
    </HomeContainer>
  );
};

export default ELDComponent;
