import styled from "styled-components";

export const ComContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ComTitle = styled.div`
  margin: 96.53px 0 96px;
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.02em;
  max-width: 580px;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 640px) {
    font-size: 25px;
    margin: 40px 0;
    width: 90%;
    line-height: 35px;
  }
`;
export const MissionOut = styled.div`
  width: 100%;
  background: #f0f1f8;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MissionPart = styled.div`
  max-width: 1440px;
  padding: 100px 0;
  @media screen and (max-width: 1300px) {
    width: 90%;
  }
  @media screen and (max-width: 1070px) {
    display: flex;
  }
  @media screen and (max-width: 500px) {
    padding: 50px 0;
  }
`;
export const MTop = styled.div`
  display: flex;
  align-items: center;
  gap: 120px;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
  @media screen and (max-width: 1070px) {
    gap: 50px;
    justify-content: center;
  }
  @media screen and (max-width: 930px) {
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    gap: 30px;
  }
`;
export const MTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media screen and (max-width: 930px) {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const MTopRight = styled.img`
  @media screen and (max-width: 1380px) {
    width: 500px;
  }
  @media screen and (max-width: 1170px) {
    width: 400px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
export const MBottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 100px;
  gap: 120px;
  @media screen and (max-width: 1050px) {
    gap: 50px;
  }
  @media screen and (max-width: 860px) {
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    margin-top: 50px;
  }
`;
export const MBottomLeft = styled.div`
  width: 520px;
  height: 400px;
  background: #d9d9d9;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${(props) => props.f});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
  @media screen and (max-width: 1100px) {
    height: 300px;
  }
  @media screen and (max-width: 860px) {
    width: 450px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
export const MBottomRight = styled.div`
  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const MTopTitle = styled.div`
  text-align: left;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  font-family: JakartaBold;
  margin-bottom: 24px;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 860px) {
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    font-size: 25px;
    margin-bottom: 0px;
  }
`;
export const MTopContent = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #4d4d4d;
  width: 454px;
  @media screen and (max-width: 1050px) {
    width: 380px;
  }
  @media screen and (max-width: 860px) {
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
export const ValuePart = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Vtitle = styled.div`
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 500px) {
    font-size: 25px;
  }
`;
export const VSub = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  text-align: center;
  margin: 24px 0 64px;
  color: #4d4d4d;
  width: 638px;
  @media screen and (max-width: 740px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    margin: 20px 0 40px;
  }
`;
export const ValuePartOut = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  @media screen and (max-width: 500px) {
    padding-top: 50px;
  }
`;
export const VItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 24px;
  @media screen and (max-width: 1200px) {
    width: 90%;
  }
`;
export const VCircle = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #99beff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;
export const VItem = styled.div`
  width: 309px;
  height: 250px;
  padding: 24px 24px 0 24px;
  background: #f2f2f2;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
  :hover {
    background-color: #005dfe;
    .v-item-text {
      color: #fff;
    }
    .v-item-img-bg {
      background-color: #fff;
    }
  }
  @media screen and (max-width: 400px) {
    width: 90%;
    height: auto;
    padding-bottom: 35px;
  }
`;
export const VItemContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  text-align: center;
  color: #000000;
`;
export const GPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d9d9d9;
  height: 450px;
  background: url(${(props) => props.f});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 500px) {
    height: 320px;
    img {
      width: 40px;
    }
  }
`;
export const StoryOut = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 56px 0 100px;
  flex-direction: column;
`;
export const StoryPart = styled.div`
  max-width: 1440px;
  display: flex;
  padding: 0 160px;
  width: calc(100% - 320px);
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1500px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }
  @media screen and (max-width: 1280px) {
    padding: 0 30px;
    width: calc(100% - 60px);
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    gap: 30px;
    padding: 0;
    width: 100%;
  }
`;
export const StoryLeft = styled.img`
  width: 800px;
  @media screen and (max-width: 1650px) {
    width: 600px;
  }
  @media screen and (max-width: 650px) {
    width: 90%;
  }
`;
export const StoryRight = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #4d4d4d;
  text-align: left;
  width: 482px;

  @media screen and (max-width: 1170px) {
    width: 350px;
  }
  @media screen and (max-width: 1050px) {
    text-align: center;
    width: 480px;
  }
  @media screen and (max-width: 650px) {
    width: 90%;
  }
`;
export const MapPart = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
  align-items: center;
  margin: 0 0 100px 0;
  @media screen and (max-width: 1500px) {
    justify-content: center;
  }
  @media screen and (max-width: 1440px) {
    justify-content: space-between;
    gap: 0;
    padding: 0 100px;
    width: calc(100% - 200px);
  }

  @media screen and (max-width: 1280px) {
    padding: 0 30px;
    width: calc(100% - 60px);
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;
export const MapLeft = styled.img`
  @media screen and (max-width: 1500px) {
    width: 700px;
  }
  @media screen and (max-width: 1190px) {
    width: 60%;
  }
  @media screen and (max-width: 1050px) {
    width: 90%;
  }
`;
export const MapRight = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #005dfe;
  padding: 80px 40px;
  @media screen and (max-width: 1050px) {
    margin-top: 30px;
    align-items: center;
  }
`;
export const MapTitle = styled.div`
  font-size: 36px;
  line-height: 46px;
  font-family: JakartaBold;
  width: 325px;
  letter-spacing: -0.02em;
  color: #fff;
  margin-bottom: 80px;
  @media screen and (max-width: 1050px) {
    text-align: center;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 25px;
    width: 90%;
  }
`;
export const MapLocate = styled.div`
  display: flex;
  gap: 48px;
  @media screen and (max-width: 1050px) {
    margin-top: 30px;
    margin-left: 80px;
  }
  @media screen and (max-width: 800px) {
    margin-left: 0;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 30px;
  }
`;
export const LocateCA = styled.div``;
export const LCATitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: #fff;
  margin-bottom: 13px;
  span {
    font-size: 20px;
    font-weight: 700;
  }
  @media screen and (max-width: 500px) {
    font-size: 20px;
    span {
      font-size: 15px;
    }
  }
`;
export const LCAdress = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  gap: 20px;
  span {
    line-height: 180%;
  }
`;
export const MapOut = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const CareerPart = styled.div`
  max-width: 1206px;
  padding: 0 74px 0 160px;
  width: calc(100% - 234px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
  @media screen and (max-width: 1280px) {
    width: calc(100% - 160px);
    max-width: auto;
    padding: 0 80px;
  }
  @media screen and (max-width: 1000px) {
    width: calc(100% - 60px);
    padding: 0 30px;
  }
  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
`;
export const CareerTitle = styled.div`
  font-size: 36px;
  line-height: 46px;
  font-family: JakartaBold;
  color: #005dfe;
  letter-spacing: -0.02em;
  @media screen and (max-width: 420px) {
    font-size: 25px;
  }
`;
export const CareerContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  margin: 40px 0 16px;
  color: #4d4d4d;
  @media screen and (max-width: 850px) {
    margin: 0px 0 16px;
  }
`;
export const CarrerInterest = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  /* identical to box height, or 20px */

  color: #000000;
`;
export const CareerBtn = styled.div`
  width: 227px;
  height: 48px;
  background: #005dfe;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  margin-top: 40px;
  line-height: 225%;
`;
export const CareerLeft = styled.div`
  text-align: left;
  width: 482px;
  @media screen and (max-width: 1200px) {
    width: 400px;
  }

  @media screen and (max-width: 850px) {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
  }
  @media screen and (max-width: 420px) {
    width: 90%;
  }
`;
export const CareerRight = styled.img`
  width: 526px;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const CaLeft = styled.div`
  background: #d9d9d9;
  width: 100%;
  height: 100%;
  background: url(${(props) => props.f});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
export const CaRight = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const CaaTop = styled.div`
  background: #d9d9d9;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background: url(${(props) => props.f});
  background-size: cover;
  background-position: center;
`;
export const MissionWrapper = styled.div``;
export const ComSubWrapper = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.2em;
  margin-bottom: 24px;
  color: #005dfe;
`;
export const StoryTitle = styled.div`
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.02em;
  font-family: JakartaBold;
  color: #000000;
  margin-bottom: 24px;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 780px) {
    font-size: 25px;
    margin-right: 80px;
    white-space: nowrap;
  }
  @media screen and (max-width: 620px) {
    margin-right: 0;
  }
`;
