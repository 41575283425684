import styled from "styled-components";

export const HomeContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HomeMain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const TrackTitle = styled.div`
  font-family: JakartaBold;
  font-weight: 700;
  font-size: 36px;
  max-width: 340px;
  line-height: 125%;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 1100px) {
    margin-top: 30px;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
  @media screen and (max-width: 420px) {
    font-size: 20px;
    width: 225px;
  }
`;
export const FaqPartOut = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 0 120px;
  align-items: center;
  @media screen and (max-width: 500px) {
    padding: 50px 0;
  }
`;
export const ProductPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ProductTitle = styled.div`
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
  }
`;
export const ProductContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #4d4d4d;
  margin: 24px 0 80px;
  width: 90%;
  @media screen and (max-width: 400px) {
    font-size: 12px;
    line-height: 150%;
    margin: 0px 0 40px;
  }
`;
export const ProductItems = styled.div`
  display: flex;
  gap: 48px;
  @media screen and (max-width: 1250px) {
    width: 90%;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const ProductItem = styled.div`
  width: 288px;
  background: #f0f1f8;
  border-radius: 16px;
  padding: 40px 40px 70px;
  position: relative;
  img {
    width: 100%;
  }
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    width: 288px;
  }
  @media screen and (max-width: 430px) {
    width: calc(100% - 80px);
  }
  @media screen and (max-width: 400px) {
  }
`;
export const ProductItemContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #4d4d4d;
  span {
    font-size: 32px;
    line-height: 125%;
    font-family: JakartaBold;
    color: #1d1d1d;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 1140px) {
    font-size: 14px;
    span {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 400px) {
    line-height: 25px;
    font-size: 12px;
    span {
      font-size: 20px;
    }
  }
`;
export const LearnMoreBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 16px;
  line-height: 175%;
  color: #005dfe;
  cursor: pointer;
  margin-top: 16px;
  position: absolute;
  bottom: 20px;
  right: 50px;
`;
export const CarPartOut = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
  width: 100%;
  @media screen and (max-width: 1240px) {
    align-items: center;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 920px) {
    height: auto;
  }
`;
export const CarImg = styled.img`
  @media screen and (max-width: 660px) {
    width: 90%;
  }
`;
export const CarPart = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 120px;
  @media screen and (max-width: 1480px) {
    margin-right: 0;
  }
  @media screen and (max-width: 490px) {
    align-items: center;
  }
`;
export const CarContent = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 125%;
  color: #1d1d1d;
  max-width: 487px;
  text-align: left;
  @media screen and (max-width: 1020px) {
    font-size: 25px;
    max-width: 400px;
  }
  @media screen and (max-width: 1000px) {
    text-align: center;
  }
  @media screen and (max-width: 490px) {
    font-size: 18px;
    max-width: 90%;
  }
`;
export const CarBtns = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 64px;
  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 500px) {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
`;
export const CarLearnBtn = styled.div`
  font-family: JakartaBold;
  font-size: 16px;
  cursor: pointer;
  line-height: 175%;
  color: #005dfe;

  width: 135px;
  height: 44px;
  border: 2px solid #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 400px) {
    width: 100px;
    height: 35px;
    font-size: 12px;
  }
`;
export const CarStartBrn = styled.div`
  font-family: JakartaBold;
  font-size: 16px;
  line-height: 175%;
  color: #ffffff;
  cursor: pointer;
  width: 201px;
  height: 44px;
  background: #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  .car-start-btn {
    margin-left: 9px;
  }
  @media screen and (max-width: 400px) {
    width: 160px;
    height: 35px;
    font-size: 12px;
  }
`;
export const CarPartC = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  height: 100%;

  @media screen and (max-width: 1480px) {
    width: calc(100% - 60px);
    padding: 0 60px;
  }
  @media screen and (max-width: 1180px) {
    gap: 30px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const LogPartOut = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 0;
`;
export const LogPart = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const LogTitle = styled.div`
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.02em;
  max-width: 726px;
  margin: 8px 0 0px;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 800px) {
    font-size: 30px;
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const LogmarginDiv = styled.div`
  /* height: 150px; */
  height: 70px;
`;

export const FTitle = styled.div`
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  width: 90%;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 830px) {
    font-size: 27px;
    line-height: 35px;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const FContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #4d4d4d;
  margin: 24px 0 40px;
  width: 90%;
`;

export const FBtn = styled.div`
  gap: 4px;
  width: 169px;
  margin-top: 40px;
  height: 40px;
  background: #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 175%;
    letter-spacing: 0.1em;
    color: #ffffff;
  }
  .arrow-right-class {
    color: #fff;
  }
  @media screen and (max-width: 760px) {
    margin-top: 0;
  }
`;
export const SupportPart = styled.div`
  display: flex;
  margin-top: 80px;
  width: 100%;
  @media screen and (max-width: 1450px) {
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
  }
`;
export const SupportImg = styled.img`
  @media screen and (max-width: 1420px) {
    width: 400px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
export const SupportRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 104px;
  text-align: left;

  @media screen and (max-width: 1160px) {
    margin-left: 50px;
  }
  @media screen and (max-width: 930px) {
    align-items: center;
    text-align: center;
    margin-left: 0;
    margin-top: 40px;
    order: 2;
  }
`;
export const Slists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  @media screen and (max-width: 530px) {
    width: 90%;
    gap: 20px;
  }
`;
export const STop = styled.div`
  display: flex;
  gap: 96px;
  @media screen and (max-width: 1200px) {
    gap: 40px;
  }
  @media screen and (max-width: 530px) {
    flex-direction: column;
    gap: 20px;
  }
`;
export const SItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 530px) {
    flex-direction: row;
  }
`;
export const SCircle = styled.div`
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccdfff;
  margin-right: 16px;
`;
export const SItemContent = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 175%;
  color: #1d1d1d;
  width: ${(props) => (props.w ? props.w : "175px")};
  @media screen and (max-width: 1080px) {
    width: ${(props) => (props.w ? "180px" : "150px")};
    font-size: 15px;
  }
  @media screen and (max-width: 930px) {
    text-align: left;
  }
  @media screen and (max-width: 530px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`;
export const FSubWrapper = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.2em;
  color: #005dfe;
  margin-bottom: 24px;
`;
export const LogSubWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 24px;
  line-height: 200%;
  text-align: center;
  color: #4d4d4d;
  width: 90%;
`;
export const CarTitle = styled.div`
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.02em;
  max-width: 400px;
  text-align: left;
  color: #1d1d1d;
  margin-bottom: 24px;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 1000px) {
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
