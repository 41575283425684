import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @import styles
import {
  HeaderContainer,
  HeaderMain,
  LogoPart,
  LogoImg,
  SubMenuPart,
  ContactBtn,
  SubItem,
  SubSubMenu,
  SSItem,
  DownItem,
  SubMenuWrapper,
  LoginCircle,
  HRight,
} from "./header.style";
import { BsChevronDown } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { IoMdLogIn } from "react-icons/io";
// @import resource

const HeaderComponent = () => {
  const navigate = useNavigate();
  const [mobileTab, setMobileTab] = useState(false);
  const [productFlag, setProductFlag] = useState(false);
  const [resourceFlag, setResourceFlag] = useState(false);
  return (
    <HeaderContainer>
      <HeaderMain>
        <LogoPart onClick={() => navigate("/")}>
          <LogoImg
            src="/assets/image/header-logo.png"
            alt=""
            draggable={false}
          />
        </LogoPart>
        <SubMenuPart flag={mobileTab}>
          <SubItem
            onMouseEnter={() => {
              setProductFlag(true);
            }}
            onMouseLeave={() => {
              setProductFlag(false);
            }}
          >
            <DownItem
              onClick={() => {
                setProductFlag(!productFlag);
                setResourceFlag(false);
              }}
            >
              <span>PRODUCTS</span>
              <BsChevronDown />
            </DownItem>

            {productFlag && (
              <SubMenuWrapper className="asdfasdffdas">
                <SubSubMenu>
                  <SSItem
                    onClick={() => {
                      navigate("/tms");
                      setProductFlag(false);
                      setMobileTab(false);
                    }}
                  >
                    TMS
                  </SSItem>
                  <SSItem
                    onClick={() => {
                      navigate("/eld");
                      setMobileTab(false);
                      setProductFlag(false);
                    }}
                  >
                    ELD
                  </SSItem>
                  <SSItem
                    onClick={() => {
                      navigate("/dashcams");
                      setMobileTab(false);
                      setProductFlag(false);
                    }}
                  >
                    DASH CAM
                  </SSItem>
                </SubSubMenu>
              </SubMenuWrapper>
            )}
          </SubItem>
          <SubItem
            onClick={() => {
              navigate("/services");
              setMobileTab(false);
              setProductFlag(false);
              setResourceFlag(false);
            }}
          >
            <span>SERVICES</span>
          </SubItem>
          <SubItem
            onClick={() => {
              navigate("/company");
              setMobileTab(false);
              setProductFlag(false);
              setResourceFlag(false);
            }}
          >
            <span>COMPANY</span>
          </SubItem>
          {/* <SubItem
            onClick={() => {
              navigate("/blog");
              setMobileTab(false);
              setProductFlag(false);
              setResourceFlag(false);
            }}
          >
            <span>BLOG</span>
          </SubItem> */}
          <SubItem
            onClick={() => {
              navigate("/contact");
              setMobileTab(false);
              setProductFlag(false);
              setResourceFlag(false);
            }}
          >
            <span>CONTACT</span>
          </SubItem>
          {/* <SubItem>
            <DownItem
              onClick={() => {
                setResourceFlag(!resourceFlag);
                setProductFlag(false);
              }}
            >
              <span>RESOURCES</span>
              <BsChevronDown />
            </DownItem>
            {resourceFlag && (
              <SubSubMenu f={true}>
                <SSItem
                  onClick={() => {
                    setResourceFlag(false);
                    setMobileTab(false);
                  }}
                >
                  CUSTOMER STORIES
                </SSItem>
                <SSItem
                  onClick={() => {
                    setMobileTab(false);
                    setResourceFlag(false);
                  }}
                >
                  SUPPORT CENTER
                </SSItem>
                <SSItem
                  onClick={() => {
                    setMobileTab(false);
                    setResourceFlag(false);
                  }}
                >
                  GUIDES
                </SSItem>
                <SSItem
                  onClick={() => {
                    setMobileTab(false);
                    setResourceFlag(false);
                  }}
                >
                  BRAND ASSETS
                </SSItem>
              </SubSubMenu>
            )}
          </SubItem> */}
        </SubMenuPart>
        <HRight>
          <a href="https://app.awako.ai/#/login" target={"_blank"}>
            <span>LOGIN</span>
          </a>
          <ContactBtn
            onClick={() => {
              navigate("/contact");
              setMobileTab(false);
            }}
          >
            CONTACT US NOW
          </ContactBtn>
          {/* <a href="https://app.awako.ai/#/login" target={"_blank"}>
            <LoginCircle>
              <IoMdLogIn />
            </LoginCircle>
          </a> */}
          <FaBars
            className="mobile-bar"
            onClick={() => setMobileTab(!mobileTab)}
          />
        </HRight>
      </HeaderMain>
    </HeaderContainer>
  );
};

export default HeaderComponent;
