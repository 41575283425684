import React, { useState, useEffect } from "react";

// @import styles
import {
  HomeContainer,
  HomeMain,
  FaqPartOut,
  ProductPart,
  ProductTitle,
  ProductContent,
  ProductItems,
  ProductItem,
  ProductItemContent,
  LearnMoreBtn,
  CarPartOut,
  CarImg,
  CarPart,
  CarContent,
  CarBtns,
  CarLearnBtn,
  CarStartBrn,
  CarPartC,
  LogPartOut,
  LogPart,
  LogTitle,
  LogmarginDiv,
  SupportPart,
  SupportImg,
  SupportRight,
  Slists,
  STop,
  SItem,
  SCircle,
  SItemContent,
  LogSubWrapper,
  CarTitle,
} from "./style";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";
import { StrapiAPI } from "../../../actions/config";
import { Link } from "react-router-dom";
// @import resource

const ELDComponent = () => {
  const [homeData, setHomeData] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const [strapiData, setStrapiData] = useState([]);

  const fetchData = () => {
    axios.get(`${StrapiAPI}/commons`).then((awako) => {
      if (awako) {
        setCommonData(awako.data);
      }
    });
    axios.get(`${StrapiAPI}/homes`).then((awako) => {
      if (awako) {
        setHomeData(awako.data);
      }
    });
    axios.get(`${StrapiAPI}/eld-products`).then((awako) => {
      if (awako) {
        setStrapiData(awako.data);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <HomeContainer>
      <HomeMain>
        <LogPartOut>
          <LogPart>
            <LogTitle>
              Need help with <span>Safety & Compliance?</span>
            </LogTitle>
            <LogSubWrapper>
              Awako has former FMCSA employees on staff to help ensure
              compliance and notify you of problems so you can stay ahead.
            </LogSubWrapper>
            <SupportPart>
              <SupportImg
                src={
                  strapiData &&
                  StrapiAPI +
                    strapiData.filter((e) => e.name == "eld-offer")[0]?.media
                      ?.url
                }
              />
              <SupportRight>
                <Slists>
                  <STop>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/tmsSteps/ico2.png" alt="" />
                      </SCircle>
                      <SItemContent>HOS logs audit</SItemContent>
                    </SItem>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/eldIco/note.png" alt="" />
                      </SCircle>
                      <SItemContent>DOT & CHP audit preparations</SItemContent>
                    </SItem>
                  </STop>
                  <STop>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/tmsSteps/search.png" alt="" />
                      </SCircle>
                      <SItemContent>Mock terminal inspections</SItemContent>
                    </SItem>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/eldIco/chart.png" alt="" />
                      </SCircle>
                      <SItemContent w="214px">
                        Equipment maintenance evaluations
                      </SItemContent>
                    </SItem>
                  </STop>
                  <STop>
                    <SItem>
                      <SCircle>
                        <img src="/assets/image/eldIco/filter.png" alt="" />
                      </SCircle>
                      <SItemContent>Drug and Alcohol programs</SItemContent>
                    </SItem>
                  </STop>
                </Slists>
              </SupportRight>
            </SupportPart>
            <LogmarginDiv />
          </LogPart>
        </LogPartOut>
        <FaqPartOut>
          <ProductPart>
            <ProductTitle>
              Product <span>Snippets</span>
            </ProductTitle>
            <ProductContent>
              Our Powerful Solutions to Help You Manage Your Fleet
            </ProductContent>
            <ProductItems>
              <ProductItem>
                <img
                  src={
                    homeData &&
                    StrapiAPI +
                      homeData.filter((e) => e.name == "tms")[0]?.media?.url
                  }
                  alt=""
                />
                <ProductItemContent>
                  <span>TMS</span>An all-in-one platform powered by AI that
                  helps you manage your all of your business in one
                  user-friendly and powerful solution.
                  <Link to="/tms">
                  <LearnMoreBtn>
                    Learn more <BsArrowRight />
                  </LearnMoreBtn>
                  </Link>
                </ProductItemContent>
              </ProductItem>
              <ProductItem>
                <img
                  src={
                    homeData &&
                    StrapiAPI +
                      homeData.filter((e) => e.name == "eld")[0]?.media?.url
                  }
                  alt=""
                />
                <ProductItemContent>
                  <span>ELD</span>Advanced electronic logging devices  to help
                  automate and ensure compliance with  FMCSA and DOT
                  regulations. Available in both Bluetooth and Hard-wired
                  versions.
                  <Link to="/eld">
                  <LearnMoreBtn>
                    Learn more <BsArrowRight />
                  </LearnMoreBtn>
                  </Link>
                </ProductItemContent>
              </ProductItem>
              <ProductItem>
                <img
                  src={
                    homeData &&
                    StrapiAPI +
                      homeData.filter((e) => e.name == "dashcam")[0]?.media?.url
                  }
                  alt=""
                />
                <ProductItemContent>
                  <span>DashCam</span>High-quality onboard dash cameras provides
                  exceptional video recording and utilizes vehicle telematics to
                  provide a variety of safety and economic data and real-time
                  alerts to both drivers and management.
                  <Link to="/dashcams">
                  <LearnMoreBtn>
                    Learn more <BsArrowRight />
                  </LearnMoreBtn>
                  </Link>
                </ProductItemContent>
              </ProductItem>
            </ProductItems>
          </ProductPart>
        </FaqPartOut>
        <CarPartOut>
          <CarPartC>
            <CarImg
              src={
                commonData &&
                StrapiAPI +
                  commonData.filter((e) => e.name == "trans")[0]?.media?.url
              }
              alt=""
              draggable={false}
            />
            <CarPart>
              <CarTitle>
                Taking Your Fleet Into the <span>Future</span>
              </CarTitle>
              <CarContent>
                Transition your business to experience the true power of a
                single platform to manage your entire operation and let Awako
                take your company into the future of fleet management.
              </CarContent>
              {/* <CarBtns>
                <CarLearnBtn>Learn more</CarLearnBtn>
                <CarStartBrn>
                  Get started now <BsArrowRight className="car-start-btn" />
                </CarStartBrn>
              </CarBtns> */}
            </CarPart>
          </CarPartC>
        </CarPartOut>
      </HomeMain>
    </HomeContainer>
  );
};

export default ELDComponent;
