import styled from "styled-components";

export const HomeContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HomeMain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const FleetPart = styled.div`
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-bottom: 200px;
  margin-top: 50px;
  @media screen and (max-width: 1480px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 1380px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 80px;
  }
`;
export const FleetLeft = styled.div`
  max-width: 525px;
  text-align: left;
  margin-left: 120px;
  margin-top: 50px;
  @media screen and (max-width: 1250px) {
    margin-left: 50px;
  }
  @media screen and (max-width: 1100px) {
    margin-right: 20px;
  }
  @media screen and (max-width: 1050px) {
    order: 2;
    margin-right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 90%;
  }
`;

export const FleetRight = styled.div`
  width: 700px;
  margin-left: 40px;
  @media screen and (max-width: 1380px) {
    width: 570px;
    margin-left: 0;
    margin-right: 120px;
  }

  @media screen and (max-width: 1250px) {
    margin-right: 30px;
    /* width: 500px; */
  }
  @media screen and (max-width: 1000px) {
    width: 400px;
  }
  @media screen and (max-width: 1050px) {
    margin-bottom: 30px;
    margin-right: 0;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;
export const FSubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #005dfe;
`;
export const FTitle = styled.div`
  font-family: JakartaBold;
  font-weight: 700;
  font-size: 32px;
  max-width: 400px;
  line-height: 125%;
  color: #1d1d1d;
  margin-top: 24px;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 1090px) {
    max-width: 400px;
    font-size: 25px;
    margin-top: 10px;
  }
  @media screen and (max-width: 800px) {
    max-width: 80%;
  }
  @media screen and (max-width: 530px) {
    max-width: 90%;
  }
  @media screen and (max-width: 470px) {
    font-size: 20px;
  }
`;
export const TrackTitle = styled.div`
  font-family: JakartaBold;
  font-weight: 700;
  font-size: 32px;
  max-width: 420px;
  line-height: 125%;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 1090px) {
    max-width: 400px;
    font-size: 25px;
    margin-top: 10px;
  }
  @media screen and (max-width: 800px) {
    max-width: 80%;
  }
  @media screen and (max-width: 530px) {
    max-width: 90%;
  }
  @media screen and (max-width: 470px) {
    font-size: 20px;
  }
`;
export const FContent = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 200%;
  color: #4d4d4d;
  margin: 24px 0 50px;
  @media screen and (max-width: 1090px) {
    font-size: 18px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 470px) {
    line-height: 180%;
    font-size: 15px;
  }
`;
export const TrackContent = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 200%;
  color: #4d4d4d;
  margin: 24px 0 80px;
  max-width: 500px;
  @media screen and (max-width: 1090px) {
    font-size: 18px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 470px) {
    line-height: 180%;
    font-size: 15px;
  }
`;
export const FBtn = styled.div`
  gap: 4px;
  width: 169px;
  margin-top: 40px;
  height: 40px;
  background: #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 175%;
    letter-spacing: 0.1em;
    color: #ffffff;
  }
  .arrow-right-class {
    color: #fff;
  }
`;

export const PlatFormPart = styled.div`
  background: #f0f1f8;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
`;
export const TabsPart = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 718px;
  height: 80px;
  background: #005dfe;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: -40px;
  align-items: center;
  padding: 0 80px;
  width: 100%;
  @media screen and (max-width: 1030px) {
    max-width: 400px;
    padding: 0 30px;
  }
  @media screen and (max-width: 800px) {
    height: 50px;
    margin-top: -25px;
  }
  @media screen and (max-width: 590px) {
    max-width: 300px;
    padding: 0 20px;
  }
  @media screen and (max-width: 400px) {
    max-width: 250px;
  }
`;
export const PlatMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 80px;
  width: 100%;
  @media screen and (max-width: 970px) {
    flex-direction: column;
    margin-top: 40px;
  }
`;
export const PlatLeft = styled.img`
  width: 400px;
  @media screen and (max-width: 1120px) {
    width: 350px;
  }
  @media screen and (max-width: 460px) {
    width: 80%;
  }
`;
export const PlatFormMain = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const PlatRight = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 970px) {
    margin-top: 40px;
    width: 450px;
  }
  @media screen and (max-width: 460px) {
    width: 90%;
  }
`;
export const OneTab = styled.div`
  font-family: ${(props) => (props.select ? "JakartaBold" : "Jakarta")};
  height: 100%;
  font-size: 20px;
  cursor: pointer;
  line-height: 125%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 130px;
  border-bottom: ${(props) => props.select && "3px solid #fff"};
  @media screen and (max-width: 1030px) {
    width: 100px;
  }
  @media screen and (max-width: 800px) {
    font-size: 15px;
  }
  @media screen and (max-width: 590px) {
    width: 80px;
  }
  @media screen and (max-width: 400px) {
    width: 60px;
    font-size: 12px;
  }
`;
export const PlatTitle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 600px;
  span {
    font-weight: 300;
    text-align: left;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 8px;
    color: #005dfe;
    @media screen and (max-width: 520px) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 490px) {
      margin-left: 40px;
    }
    @media screen and (max-width: 460px) {
      margin-left: 0;
    }
    @media screen and (max-width: 410px) {
      margin-bottom: 5px;
    }
  }
  font-family: JakartaBold;
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  @media screen and (max-width: 1215px) {
    font-size: 28px;
  }
  @media screen and (max-width: 1160px) {
    max-width: 450px;
  }
  @media screen and (max-width: 970px) {
    font-size: 23px;
  }
  @media screen and (max-width: 520px) {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 410px) {
    line-height: 27px;
    font-size: 20px;
  }
`;
export const PlatItemPart = styled.div`
  margin-top: 40px;
`;
export const PlatLearnBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  line-height: 175%;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color: #005dfe;

  width: 294px;
  height: 40px;
  border: 2px solid #005dfe;
  border-radius: 64px;
  margin-top: 48px;
  margin-left: 40px;
  @media screen and (max-width: 970px) {
    width: 180px;
    margin-left: 0;
  }
  @media screen and (max-width: 490px) {
    margin-top: 40px;
  }
`;
export const PlatRow = styled.div`
  display: flex;
  margin-top: 24px;
  margin-left: 40px;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 1060px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 970px) {
    margin-left: 0;
  }
  @media screen and (max-width: 490px) {
    width: 315px;
    margin-left: 40px;
  }
  @media screen and (max-width: 460px) {
    width: 250px;
  }
  @media screen and (max-width: 360px) {
    margin-left: 20px;
  }
`;
export const PlatItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  span {
    width: ${(props) => (props.w ? props.w : "186px")};
    font-weight: 600;
    text-align: left;
    font-size: 18px;
    line-height: 175%;
    color: #1d1d1d;
    @media screen and (max-width: 1215px) {
      font-size: 15px;
      width: ${(props) => props.w == "234px" && "190px"};
      width: ${(props) => !props.w && "200px"};
    }
    @media screen and (max-width: 1060px) {
      font-size: 15px;
      width: ${(props) => props.w == "234px" && "170px"};
      width: ${(props) => !props.w && "120px"};
    }
    @media screen and (max-width: 500px) {
      width: 90%;
    }
  }
`;
export const PlatItemImg = styled.img``;
export const PlatFormItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  gap: 28px;
  @media screen and (max-width: 1060px) {
    margin-left: 20px;
    justify-content: flex-start;
  }
  @media screen and (max-width: 970px) {
    margin-left: 0;
  }
  @media screen and (max-width: 500px) {
    gap: 15px;
  }
  @media screen and (max-width: 490px) {
    flex-direction: column;
    margin-left: 40px;
  }
  @media screen and (max-width: 360px) {
    margin-left: 20px;
  }
`;
export const TabLine = styled.div`
  background-color: #ccdfff;
  opacity: 0.4;
  height: 40px;
  width: 2px;
`;
export const RedefinedPart = styled.div`
  padding: 80px 0;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
`;
export const RedefinedTitle = styled.div`
  font-family: JakartaBold;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  text-align: center;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 900px) {
    font-size: 25px;
    width: 90%;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
  }
`;
export const RedefinedImg = styled.div`
  margin-top: 40px;
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
export const QuotePart = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f0f1f8;
`;
export const QuoteMain = styled.div`
  max-width: 1440px;
  text-align: left;
  position: relative;
  padding: 110px 0 80px;
  @media screen and (max-width: 800px) {
    padding: 80px 0;
  }
  @media screen and (max-width: 600px) {
    padding: 50px 0;
  }
`;
export const QuoteContent = styled.div`
  max-width: 988px;
  font-weight: 400;
  font-size: 20px;
  line-height: 200%;
  color: #4d4d4d;
  position: relative;
  @media screen and (max-width: 1070px) {
    max-width: 90%;
  }
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
  @media screen and (max-width: 470px) {
    font-size: 15px;
  }
`;
export const QuoteCEO = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 175%;
  cursor: pointer;
  color: #005dfe;
  margin-top: 48px;
  @media screen and (max-width: 1070px) {
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    font-size: 18px;
    margin-top: 30px;
  }
  @media screen and (max-width: 470px) {
    font-size: 15px;
  }
`;
export const QuoteImg = styled.img`
  position: absolute;
  top: -50px;
  left: -50px;
  @media screen and (max-width: 1150px) {
    width: 60px;
    top: -25px;
    left: -20px;
  }
  @media screen and (max-width: 600px) {
    width: 40px;
    top: -10px;
    left: -10px;
  }
`;

export const TrackPart = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  overflow: hidden;
`;
export const TrackLeft = styled.div`
  margin-left: 120px;
  text-align: left;
  @media screen and (max-width: 1250px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 800px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    order: 2;
    margin-top: 30px;
  }
  @media screen and (max-width: 550px) {
    width: 90%;
  }
`;
export const TrackRight = styled.img`
  @media screen and (max-width: 1500px) {
    margin-right: 30px;
  }
  @media screen and (max-width: 920px) {
    width: 400px;
  }
  @media screen and (max-width: 800px) {
    margin-right: 0;
  }
  @media screen and (max-width: 550px) {
    width: 90%;
  }
`;
export const TrackMain = styled.div`
  width: 1440px;
  display: flex;
  z-index: 2;
  align-items: center;
  padding-bottom: 50px;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
export const TrackBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  @media screen and (max-width: 375px) {
    flex-direction: column;
    gap: 15px;
  }
`;
export const TrackLearnBtn = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.1em;
  color: #005dfe;

  width: 107px;
  height: 40px;
  border: 2px solid #005dfe;
  border-radius: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const TrackBtn = styled.div`
  width: 169px;
  height: 40px;
  background: #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;

  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.1em;
  color: #ffffff;
  span {
    margin-right: 9px;
  }
`;
export const LearnBtnOut = styled.div`
  @media screen and (max-width: 490px) {
    display: flex;
    justify-content: center;
  }
`;
export const QuoteContentOut = styled.div`
  @media screen and (max-width: 1070px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const RedLeftCircle = styled.div`
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background: #f0f1f8;
  box-shadow: 0px 160px 64px rgba(38, 28, 28, 0.01),
    0px 90px 54px rgba(38, 28, 28, 0.04), 0px 40px 40px rgba(38, 28, 28, 0.07),
    0px 10px 22px rgba(38, 28, 28, 0.08), 0px 0px 0px rgba(38, 28, 28, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    width: 330px;
    height: 330px;
  }
  @media screen and (max-width: 400px) {
    width: 300px;
    height: 300px;
  }
`;
export const RedRightCircle = styled.div`
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background: #005dfe;
  box-shadow: 0px 160px 64px rgba(38, 28, 28, 0.01),
    0px 90px 54px rgba(38, 28, 28, 0.04), 0px 40px 40px rgba(38, 28, 28, 0.07),
    0px 10px 22px rgba(38, 28, 28, 0.08), 0px 0px 0px rgba(38, 28, 28, 0.08);
  margin-left: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    width: 330px;
    height: 330px;
  }
  @media screen and (max-width: 650px) {
    margin-left: 0;
    margin-top: -30px;
  }
  @media screen and (max-width: 400px) {
    width: 300px;
    height: 300px;
  }
`;
export const LCircleTitle = styled.div`
  width: ${(props) => (props.f ? "200px" : "260px")};
  margin-bottom: 28px;
  font-family: JakartaBold;
  font-weight: 600;
  font-size: 30px;
  line-height: 175%;
  text-align: center;
  color: ${(props) => (props.f ? "#FFF" : "#1d1d1d")};
  @media screen and (max-width: 900px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;
export const LCircleLists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 900px) {
    gap: 5px;
  }
`;
export const LCircleList = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: left;
  display: flex;
  align-items: center;
  /* width: ${(props) => props.f && "250px"}; */
  color: ${(props) => (props.f ? "#fff" : "#4d4d4d")};
  .way-icon {
    margin-right: 10px;
  }
  @media screen and (max-width: 900px) {
    /* width: ${(props) => props.f && "200px"}; */
    font-size: 15px;
  }
`;
export const PlatFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column;

    gap: 15px;
  }
`;
export const CircleWrapper = styled.div`
  display: flex;
  margin-top: 60px;
  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;
