import styled from "styled-components";

export const HomeContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HomeMain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const TrackTitle = styled.div`
  font-family: JakartaBold;
  font-weight: 700;
  font-size: 36px;
  max-width: 400px;
  line-height: 125%;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 1100px) {
    margin-top: 30px;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
  @media screen and (max-width: 420px) {
    font-size: 20px;
    width: 225px;
  }
`;
export const FaqPartOut = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 120px;
  align-items: center;
`;
export const FaqPart = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const FaqSub = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #005dfe;
  @media screen and (max-width: 500px) {
    width: 90%;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;
export const FaqTitle = styled.div`
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  margin: 8px 0 56px;
  @media screen and (max-width: 800px) {
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    width: 95%;
    font-size: 25px;
    line-height: 30px;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
  }
`;
export const FaqMain = styled.div`
  gap: 40px;
  display: flex;
  margin: 0 164px;
  @media screen and (max-width: 1440px) {
    margin: 0 50px;
  }
  @media screen and (max-width: 1180px) {
    margin: 0;
    width: 90%;
  }
  @media screen and (max-width: 760px) {
    flex-direction: column;
  }
`;
export const FaqItem = styled.div`
  width: 488px;
  padding: 18px 24px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  border: ${(props) => props.selectfaq && "1px solid #005DFE"};
  cursor: pointer;
  @media screen and (max-width: 1180px) {
    width: calc(100% - 48px);
  }
`;
export const FItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    font-size: 22px;
    min-width: 22px;
    min-height: 22px;
    margin-left: 20px;
  }
`;
export const FMainContent = styled.div`
  font-family: JakartaBold;
  font-size: 18px;
  line-height: 175%;
  color: #1d1d1d;
  text-align: left;
  max-width: 373px;
  @media screen and (max-width: 1020px) {
    font-size: 15px;
  }
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`;
export const FItemContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: left;
  color: #4d4d4d;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.faq ? "flex" : "none")};
  @media screen and (max-width: 1020px) {
    font-size: 12px;
  }
`;
export const FaqMainLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 1180px) {
    width: 100%;
  }
`;
export const ProductPart = styled.div`
  margin-top: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 400px) {
    margin-top: 70px;
  }
`;
export const ProductTitle = styled.div`
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
  }
`;
export const ProductContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #4d4d4d;
  margin: 24px 0 80px;
  width: 90%;
  @media screen and (max-width: 400px) {
    font-size: 12px;
    line-height: 150%;
    margin: 0px 0 40px;
  }
`;
export const ProductItems = styled.div`
  display: flex;
  gap: 48px;
  @media screen and (max-width: 1250px) {
    width: 90%;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const ProductItem = styled.div`
  width: 288px;
  background: #f0f1f8;
  border-radius: 16px;
  padding: 40px;
  img {
    width: 100%;
  }
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    width: 288px;
  }
  @media screen and (max-width: 430px) {
    width: calc(100% - 80px);
  }
`;
export const ProductItemContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #4d4d4d;
  span {
    font-size: 32px;
    line-height: 125%;
    font-family: JakartaBold;
    color: #1d1d1d;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 1140px) {
    font-size: 14px;
    span {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 400px) {
    line-height: 25px;
    font-size: 12px;
    span {
      font-size: 20px;
    }
  }
`;
export const LearnMoreBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 16px;
  line-height: 175%;
  color: #005dfe;
  cursor: pointer;
  margin-top: 16px;
`;
export const CarPartOut = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
  width: 100%;
  @media screen and (max-width: 1240px) {
    align-items: center;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 920px) {
    height: auto;
  }
`;
export const CarImg = styled.img`
  @media screen and (max-width: 660px) {
    width: 90%;
  }
`;
export const CarPart = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 120px;
  @media screen and (max-width: 1480px) {
    margin-right: 0;
  }
  @media screen and (max-width: 490px) {
    align-items: center;
  }
`;
export const CarContent = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 125%;
  color: #1d1d1d;
  max-width: 487px;
  text-align: left;
  @media screen and (max-width: 1020px) {
    font-size: 25px;
    max-width: 400px;
  }
  @media screen and (max-width: 1000px) {
    text-align: center;
  }
  @media screen and (max-width: 490px) {
    font-size: 18px;
    max-width: 90%;
  }
`;
export const CarBtns = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 64px;
  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 500px) {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
`;
export const CarLearnBtn = styled.div`
  font-family: JakartaBold;
  font-size: 16px;
  cursor: pointer;
  line-height: 175%;
  color: #005dfe;

  width: 135px;
  height: 44px;
  border: 2px solid #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 400px) {
    width: 100px;
    height: 35px;
    font-size: 12px;
  }
`;
export const CarStartBrn = styled.div`
  font-family: JakartaBold;
  font-size: 16px;
  line-height: 175%;
  color: #ffffff;
  cursor: pointer;
  width: 201px;
  height: 44px;
  background: #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  .car-start-btn {
    margin-left: 9px;
  }
  @media screen and (max-width: 400px) {
    width: 160px;
    height: 35px;
    font-size: 12px;
  }
`;
export const CarPartC = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  height: 100%;

  @media screen and (max-width: 1480px) {
    width: calc(100% - 60px);
    padding: 0 60px;
  }
  @media screen and (max-width: 1180px) {
    gap: 30px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const DashCamPart = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  background: url(${(props) => props.f});
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 111.47px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DashContent = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const DashTitle = styled.div`
  font-size: 36px;
  font-family: JakartaBold;
  line-height: 46px;
  letter-spacing: -0.02em;
  color: #ffffff;
  @media screen and (max-width: 400px) {
    font-size: 30px;
  }
`;
export const DashSub = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #ffffff;
  margin: 24px 0 40px;
  width: 90%;
`;
export const Demo = styled.div`
  width: 233px;
  height: 48px;
  background: #005dfe;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 64px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  line-height: 175%;
  font-family: JakartaBold;
  svg {
    margin-left: 5px;
  }
`;

export const LogPartOut = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f1f8;
  padding: 100px 0;
`;
export const LogPart = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1270px) {
    width: 100%;
  }
`;
export const LogSub = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #005dfe;
  width: 90%;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;
export const LogTitle = styled.div`
  font-family: JakartaBold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.02em;
  max-width: 900px;
  margin: 8px 0 24px;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 980px) {
    width: 90%;
  }
  @media screen and (max-width: 800px) {
    line-height: 35px;
    font-size: 25px;
  }
  @media screen and (max-width: 500px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const LogContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #4d4d4d;
  @media screen and (max-width: 800px) {
    width: 90%;
    line-height: 25px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
export const LogImgTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5efff;
  border-radius: ${(props) => (!props.f ? "24px 0 0 24px" : "0 24px 24px 0")};
  width: 100%;
  @media screen and (max-width: 1070px) {
    img {
      width: 300px;
    }
  }
  @media screen and (max-width: 780px) {
    height: 300px;
    border-radius: 24px 24px 0 0;
  }
`;
export const LogImgPartOut = styled.div`
  margin-top: 40px;
  width: 1200px;
  display: flex;
  @media screen and (max-width: 1270px) {
    width: 90%;
  }
  @media screen and (max-width: 780px) {
    flex-direction: column;
  }
`;
export const LogImgRight = styled.div`
  padding: 37px 73px;
  border-radius: ${(props) => (props.f ? "24px 0 0 24px" : "0 24px 24px 0")};
  background: #e5efff;
  @media screen and (max-width: 950px) {
    padding: 30px;
  }
  @media screen and (max-width: 780px) {
    border-radius: 0 0 24px 24px;
    order: 2;
  }
`;
export const LogImgItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  border-left: ${(props) =>
    props.select ? "3px solid #005DFE" : "1px solid rgba(0, 0, 0, 0.1)"};
  padding: 21px;
  @media screen and (max-width: 780px) {
    padding: 15px;
  }
`;
export const LogCircle = styled.div`
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  background: #ccdfff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LogCircleContent = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 175%;
  text-align: left;
  width: 220px;
  color: #1d1d1d;
  opacity: ${(props) => !props.f && "0.5"};
  @media screen and (max-width: 780px) {
    width: 100%;
    font-size: 13px;
  }
`;
export const LogmarginDiv = styled.div`
  height: 200px;
  @media screen and (max-width: 500px) {
    height: 50px;
  }
`;

export const TrackPart = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 1100px) {
    height: auto;
  }
`;
export const TrackLeft = styled.div`
  margin-left: 120px;
  text-align: left;
  img {
    display: none;
    @media screen and (max-width: 1100px) {
      display: flex;
      width: 500px;
    }
    @media screen and (max-width: 600px) {
      width: 80%;
    }
  }
  @media screen and (max-width: 1250px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    align-items: center;
  }
`;
export const TrackRight = styled.img`
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
export const TrackMain = styled.div`
  width: 1440px;
  display: flex;
  padding: 80px 0;
  height: 500px;
  z-index: 2;
  background: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  @media screen and (max-width: 1190px) {
    background-size: cover;
    background-position: left;
  }
  @media screen and (max-width: 1100px) {
    justify-content: center;
    padding: 40px 0 80px;
  }
  @media screen and (max-width: 700px) {
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 0 40px;
  }
`;

export const FleetPart = styled.div`
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-bottom: 120px;
  margin-top: 78px;
  @media screen and (max-width: 830px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 760px) {
    flex-direction: column;
  }
  @media screen and (max-width: 400px) {
    padding-bottom: 70px;
    margin-top: 0;
  }
`;
export const FleetLeft = styled.div`
  max-width: 630px;
  text-align: left;
  margin-left: 120px;
  @media screen and (max-width: 1250px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 1100px) {
    margin-right: 20px;
  }
  @media screen and (max-width: 760px) {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const FleetRight = styled.img`
  /* width: 445px; */
  @media screen and (max-width: 1500px) {
    margin-right: 50px;
  }
  @media screen and (max-width: 1250px) {
    margin-right: 30px;
  }
  @media screen and (max-width: 1000px) {
    width: 380px;
  }
  @media screen and (max-width: 470px) {
    width: 90%;
    margin-right: 0;
  }
`;
export const FTitle = styled.div`
  font-family: JakartaBold;
  font-size: 33px;
  line-height: 46px;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  width: 90%;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 830px) {
    font-size: 27px;
    line-height: 35px;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const FContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #4d4d4d;
  margin: 24px 0 40px;
  width: 90%;
`;

export const FSubWrapper = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.2em;
  color: #005dfe;
  margin-bottom: 24px;
`;
export const TrackSubWrapper = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #005dfe;
`;
export const TrackContentWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #4d4d4d;
  margin-top: 24px;
  max-width: 445px;
  @media screen and (max-width: 700px) {
    text-align: center;
    width: 90%;
    line-height: 180%;
  }
`;
export const CarTitle = styled.div`
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.02em;
  max-width: 400px;
  text-align: left;
  color: #1d1d1d;
  margin-bottom: 24px;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 1000px) {
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
