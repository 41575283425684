import React, { useState, useEffect } from "react";

// @import styles
import {
  ComContainer,
  ComTitle,
  MissionOut,
  MissionPart,
  MTop,
  MTopLeft,
  MTopRight,
  MTopTitle,
  MTopContent,
  ValuePartOut,
  ValuePart,
  Vtitle,
  VSub,
  VItems,
  VCircle,
  VItem,
  VItemContent,
  StoryOut,
  StoryPart,
  StoryLeft,
  StoryRight,
  MapPart,
  MapLeft,
  MapRight,
  MapTitle,
  MapLocate,
  LocateCA,
  LCATitle,
  LCAdress,
  MapOut,
  CareerPart,
  CareerTitle,
  CareerContent,
  CarrerInterest,
  CareerBtn,
  CareerLeft,
  CareerRight,
  MissionWrapper,
  ComSubWrapper,
  StoryTitle,
} from "./company.style";
import { StrapiAPI } from "../../actions/config";
import axios from "axios";

// @import resource

const CompanyComponent = () => {
  const [strapiData, setStrapiData] = useState([]);

  const fetchData = () => {
    axios.get(`${StrapiAPI}/companies`).then((awako) => {
      if (awako) {
        setStrapiData(awako?.data);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <ComContainer>
      <ComTitle>
        <ComSubWrapper>ABOUT US</ComSubWrapper>
        Meet Your <span>New Teammates</span> - The People Behind Awako
      </ComTitle>
      <MissionOut>
        <MissionPart>
          <MTop>
            <MTopLeft>
              <MissionWrapper>
                <MTopTitle>
                  Our <span>Mission</span>
                </MTopTitle>
                <MTopContent>
                  Our mission is to listen to our customers needs, and build
                  innovative and reliable products to drive your business to the
                  next level.
                </MTopContent>
              </MissionWrapper>
              <MissionWrapper>
                <MTopTitle>
                  Our <span>Vision</span>
                </MTopTitle>
                <MTopContent>
                  Empowering trucking companies with the right technology to
                  bring efficiency and productivity to their day-to-day
                  operations
                </MTopContent>
              </MissionWrapper>
            </MTopLeft>
            <MTopRight
              src={
                strapiData &&
                StrapiAPI +
                  strapiData.filter((e) => e.name === "company-mission")[0]
                    ?.media?.url
              }
            ></MTopRight>
          </MTop>
        </MissionPart>
      </MissionOut>
      {/* our value part  */}
      <ValuePartOut>
        <ValuePart>
          <Vtitle>
            Our <span>Values</span>
          </Vtitle>
          <VSub>
            Here at Awako, we value open communication and welcome our team
            members to take initiative and provide feedback to improve customer
            success and build a world-class product in transportation
          </VSub>
          <VItems>
            <VItem>
              <VCircle className="v-item-img-bg">
                <img src="/assets/image/eldIco/chat.png" alt="" />
              </VCircle>
              <VItemContent className="v-item-text">
                Open communication: we are transparent with one another and our
                clients
              </VItemContent>
            </VItem>
            <VItem>
              <VCircle className="v-item-img-bg">
                <img src="/assets/image/eldIco/medal.png" alt="" />
              </VCircle>
              <VItemContent className="v-item-text">
                Customer success: we focus on building long-term relationships
                with customers to grow their business to the next level
              </VItemContent>
            </VItem>
            <VItem>
              <VCircle className="v-item-img-bg">
                <img src="/assets/image/eldIco/lamp.png" alt="" />
              </VCircle>
              <VItemContent className="v-item-text">
                Innovative thinkers: we bring our ideas to your team in order to
                arrive at the best practices and solutions. Being
                solution-focused.
              </VItemContent>
            </VItem>
            <VItem>
              <VCircle className="v-item-img-bg">
                <img src="/assets/image/eldIco/cup.png" alt="" />
              </VCircle>
              <VItemContent className="v-item-text">
                Glass half-full mentality: we approach every situation with a
                positive outlook and we see ourselves as a part of a larger
                group helping to move toward a common vision
              </VItemContent>
            </VItem>
            <VItem>
              <VCircle className="v-item-img-bg">
                <img src="/assets/image/eldIco/idolman.png" alt="" />
              </VCircle>
              <VItemContent className="v-item-text">
                Extreme ownership: we are 'go-getters', we hold ourselves and
                each other accountable to our commitments
              </VItemContent>
            </VItem>
          </VItems>
        </ValuePart>
      </ValuePartOut>
      <StoryOut>
        <StoryPart>
          <StoryLeft
            src={
              strapiData &&
              StrapiAPI +
                strapiData.filter((e) => e.name === "company-story")[0]?.media
                  ?.url
            }
            draggable={false}
          />
          <StoryRight>
            <StoryTitle>
              Our <span>Story</span>
            </StoryTitle>
            As both founders worked in multiple domains with decades of
            experience building different product lines. We have seen a huge
            lack of technology usage in the transportation industry. With that
            in mind, we set out to solve day-to-day operational challenges by
            developing a world-class product with cutting-edge AI technology
          </StoryRight>
        </StoryPart>
      </StoryOut>
      <MapOut>
        <MapPart>
          <MapLeft
            src={
              strapiData &&
              StrapiAPI +
                strapiData.filter((e) => e.name === "company-map")[0]?.media?.url
            }
            draggable={false}
          />
          <MapRight>
            <MapTitle>We're here for you. Say hello.</MapTitle>
            <MapLocate>
              <LocateCA>
                <LCATitle>USA</LCATitle>
                <LCAdress>
                  <span>
                    LiRCTek Inc.
                    <br />
                    5701 Lonetree BIvd Suite# 222,
                    <br />
                    Rocklin, CA 95765.
                  </span>
                  <span>
                    Telephone: +1 (916) 225-0419
                    <br />
                    E-mail: info@lirctek.com
                  </span>
                </LCAdress>
              </LocateCA>
              <LocateCA>
                <LCATitle>INDIA</LCATitle>
                <LCAdress>
                  <span>
                    LiRCTek Technologies Pvt Ltd.
                    <br />
                    202, Brigade IRV Center
                    <br />
                    Nallurhalli,
                    <br />
                    Whitefield, Bengaluru 5600566.
                  </span>
                  <span>
                    Telephone: +91 8951 774634 / 080 47059685
                    <br />
                    E-mail: info@lirctek.com
                  </span>
                </LCAdress>
              </LocateCA>
            </MapLocate>
          </MapRight>
        </MapPart>
        <CareerPart>
          <CareerLeft>
            <CareerTitle>Careers</CareerTitle>
            <CareerContent>
              <p>
                When great minds work together, great innovation happens. That
                leads to a positive impact in the world.
              </p>
              <p>You're in good company.</p>
            </CareerContent>
            <CarrerInterest>
              Interested in joining our growing team?
            </CarrerInterest>
            {/* <CareerBtn>Check out our open positions</CareerBtn> */}
          </CareerLeft>
          <CareerRight
            src={
              strapiData &&
              StrapiAPI +
                strapiData.filter((e) => e.name === "company-career")[0]?.media
                  ?.url
            }
          />
        </CareerPart>
      </MapOut>
    </ComContainer>
  );
};

export default CompanyComponent;
