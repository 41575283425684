import React, { useState, useEffect } from "react";

// @import styles
import {
  HomeContainer,
  HomeMain,
  TrackPart,
  TrackLeft,
  TrackRight,
  TrackMain,
  TrackTitle,
  LogPartOut,
  LogPart,
  LogSub,
  LogTitle,
  LogContent,
  LogImgTag,
  HosPart,
  HosItem,
  HosCircle,
  HosItemText,
  HosItemOut,
  TrackSubWrapper,
  TrackContentWrapper,
} from "./eld.style";
import Safety from "./Safety";
import { StrapiAPI } from "../../actions/config";
import axios from "axios";
// @import resource

const ELDComponent = () => {
  const [strapiData, setStrapiData] = useState([]);

  const fetchData = () => {
    axios.get(`${StrapiAPI}/eld-products`).then((awako) => {
      if (awako) {
        setStrapiData(awako.data);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <HomeContainer>
      <HomeMain>
        <TrackPart>
          <TrackMain
            bg={
              strapiData &&
              StrapiAPI +
                strapiData.filter((e) => e.name == "eld-land")[0]?.media?.url
            }
          >
            <TrackLeft>
              <TrackSubWrapper>ELECTRONIC LOGGING SERVICES</TrackSubWrapper>
              <TrackTitle>
                Automated ELD to <span>Ensure Compliance</span>
              </TrackTitle>
              <TrackContentWrapper>
                Reduce HOS violations while also reducing the workload on both
                drivers and management staff.
              </TrackContentWrapper>
            </TrackLeft>
          </TrackMain>
          {/* <TrackRight
            src={"/assets/image/eld-land.png"}
            alt=""
            draggable={false}
          /> */}
        </TrackPart>
        <LogPartOut>
          <LogPart>
            <LogSub>Electronic Logging services</LogSub>
            <LogTitle>
              A <span>Centralized System</span> to Streamline Your Fleet
              <img src="/assets/image/mark.png" alt="" draggable={false} />
            </LogTitle>
            <LogContent>
              Awako ELD solutions are compiled in one convenient system.
            </LogContent>
            <LogImgTag
              f={
                strapiData &&
                StrapiAPI +
                  strapiData.filter((e) => e.name == "eld-driver")[0]?.media
                    ?.url
              }
            ></LogImgTag>
            <HosPart>
              <HosItemOut dNum="1">
                <HosItem>
                  <HosCircle>
                    <img src="/assets/image/eldIco/refresh.png" alt="" />
                  </HosCircle>
                  <HosItemText>HOS real time monitoring</HosItemText>
                </HosItem>
              </HosItemOut>
              <HosItemOut dNum="2">
                <HosItem>
                  <HosCircle>
                    <img src="/assets/image/eldIco/contact.png" alt="" />
                  </HosCircle>
                  <HosItemText>
                    Simplified compliance DVIR & logs certification.
                  </HosItemText>
                </HosItem>
              </HosItemOut>
              <HosItemOut dNum="3">
                <HosItem>
                  <HosCircle>
                    <img src="/assets/image/eldIco/warn.png" alt="" />
                  </HosCircle>
                  <HosItemText>Real time compliance alerts</HosItemText>
                </HosItem>
              </HosItemOut>
              <HosItemOut dNum="4">
                <HosItem>
                  <HosCircle>
                    <img src="/assets/image/eldIco/note.png" alt="" />
                  </HosCircle>
                  <HosItemText>IFTA fuel tax reporting</HosItemText>
                </HosItem>
              </HosItemOut>
              <HosItemOut dNum="5">
                <HosItem>
                  <HosCircle>
                    <img src="/assets/image/eldIco/mans.png" alt="" />
                  </HosCircle>
                  <HosItemText>Team Driving</HosItemText>
                </HosItem>
              </HosItemOut>
              <HosItemOut dNum="6">
                <HosItem>
                  <HosCircle>
                    <img src="/assets/image/eldIco/man.png" alt="" />
                  </HosCircle>
                  <HosItemText>Yard move and Personal conveyance.</HosItemText>
                </HosItem>
              </HosItemOut>
              <HosItemOut dNum="7">
                <HosItem>
                  <HosCircle>
                    <img src="/assets/image/eldIco/target.png" alt="" />
                  </HosCircle>
                  <HosItemText>
                    Real time GPS tracking & auto geofencing.
                  </HosItemText>
                </HosItem>
              </HosItemOut>
              <HosItemOut dNum="8">
                <HosItem>
                  <HosCircle>
                    <img src="/assets/image/eldIco/ear.png" alt="" />
                  </HosCircle>
                  <HosItemText>Vehicle engine diagnostics.</HosItemText>
                </HosItem>
              </HosItemOut>
            </HosPart>
          </LogPart>
        </LogPartOut>
        <Safety />
      </HomeMain>
    </HomeContainer>
  );
};

export default ELDComponent;
