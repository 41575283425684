import styled from "styled-components";

export const HomeContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HomeMain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const TrackTitle = styled.div`
  font-family: JakartaBold;
  font-weight: 700;
  font-size: 36px;
  max-width: 340px;
  line-height: 125%;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 1100px) {
    margin-top: 30px;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
  @media screen and (max-width: 420px) {
    font-size: 20px;
    width: 225px;
  }
`;

export const FSubtotle = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #005dfe;
  @media screen and (max-width: 760px) {
    width: 90%;
  }
`;

export const LogPartOut = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f1f8;
`;
export const LogPart = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const LogImgRight = styled.div`
  padding: 37px 73px;
  border-radius: ${(props) => (props.f ? "24px 0 0 24px" : "0 24px 24px 0")};
  background: #e5efff;
  @media screen and (max-width: 950px) {
    padding: 30px;
  }
  @media screen and (max-width: 780px) {
    border-radius: 0 0 24px 24px;
    order: 2;
  }
`;
export const LogImgItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  border-left: ${(props) =>
    props.select ? "3px solid #005DFE" : "1px solid rgba(0, 0, 0, 0.1)"};
  padding: 21px;
  @media screen and (max-width: 780px) {
    padding: 15px;
  }
`;
export const LogCircle = styled.div`
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  background: #ccdfff;
`;
export const LogCircleContent = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 175%;
  text-align: left;
  width: 220px;
  color: #1d1d1d;
  @media screen and (max-width: 780px) {
    width: 100%;
    font-size: 13px;
  }
`;

export const FleetPart = styled.div`
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  padding-bottom: 200px;
  margin-top: 50px;
  @media screen and (max-width: 1480px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 1380px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    padding-bottom: 80px;
  }
`;
export const FleetLeft = styled.div`
  max-width: 525px;
  text-align: left;
  margin-left: 120px;
  margin-top: 50px;
  @media screen and (max-width: 1250px) {
    margin-left: 50px;
  }
  @media screen and (max-width: 1100px) {
    margin-right: 20px;
  }
  @media screen and (max-width: 1050px) {
    order: 2;
    margin-right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 90%;
  }
`;

export const FleetRight = styled.div`
  width: 700px;
  margin-left: 40px;
  @media screen and (max-width: 1380px) {
    width: 570px;
    margin-left: 0;
    margin-right: 120px;
  }

  @media screen and (max-width: 1250px) {
    margin-right: 30px;
    /* width: 500px; */
  }
  @media screen and (max-width: 1000px) {
    width: 400px;
  }
  @media screen and (max-width: 1050px) {
    margin-bottom: 30px;
    margin-right: 0;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;
export const FSubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #005dfe;
`;
export const FTitle = styled.div`
  font-family: JakartaBold;
  font-weight: 700;
  font-size: 32px;
  max-width: 520px;
  line-height: 125%;
  color: #1d1d1d;
  margin: 24px 0;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 1090px) {
    max-width: 400px;
    font-size: 25px;
    margin-top: 10px;
  }
  @media screen and (max-width: 800px) {
    max-width: 80%;
  }
  @media screen and (max-width: 530px) {
    max-width: 90%;
  }
  @media screen and (max-width: 470px) {
    font-size: 20px;
  }
`;

export const FContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #4d4d4d;
  width: 90%;
`;

export const FBtn = styled.div`
  gap: 4px;
  width: 169px;
  margin-top: 40px;
  height: 40px;
  background: #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 175%;
    letter-spacing: 0.1em;
    color: #ffffff;
  }
  .arrow-right-class {
    color: #fff;
  }
  @media screen and (max-width: 760px) {
    margin-top: 0;
  }
`;
export const SupportPart = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 180px;
  @media screen and (max-width: 1360px) {
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
export const SupportImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 530px) {
    img {
      width: 90%;
    }
  }
`;
export const SupportRight = styled.div`
  display: flex;
  margin-right: 100px;
  text-align: left;
  height: fit-content;
  align-items: center;
  @media screen and (max-width: 1360px) {
    margin-right: 0;
    margin-left: 100px;
  }
  @media screen and (max-width: 960px) {
    margin-left: 0;
    margin-top: 30px;
  }
`;
export const TabsPart = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  height: 88px;
  background: #005dfe;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: -40px;
  align-items: center;
  padding: 0 80px;
  width: 100%;
  @media screen and (max-width: 1030px) {
    max-width: 500px;
    padding: 0 30px;
    height: 60px;
    margin-top: -30px;
  }
  @media screen and (max-width: 690px) {
    max-width: 330px;
  }
  @media screen and (max-width: 500px) {
    max-width: 200px;
    height: 50px;
    margin-top: -25px;
    margin-bottom: 40px;
  }
`;

export const OneTab = styled.div`
  font-family: ${(props) => (props.select ? "JakartaBold" : "Jakarta")};
  height: 100%;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: #ffffff;
  width: 300px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${(props) => props.select && "5px solid #fff"};
  @media screen and (max-width: 1030px) {
    width: 220px;
    font-size: 26px;
  }
  @media screen and (max-width: 690px) {
    width: 140px;
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    width: 80px;
    font-size: 18px;
  }
`;

export const TabLine = styled.div`
  background-color: #ccdfff;
  opacity: 0.4;
  height: 50px;
  width: 2px;
`;
export const StepsBar = styled.div`
  height: 550px;
  border-left: 1px solid #cccccc;
  margin-right: 30px;
  position: relative;
  @media screen and (max-width: 590px) {
    height: 580px;
    margin-right: 20px;
  }
`;
export const Steplists = styled.div`
  @media screen and (max-width: 590px) {
    width: 280px;
  }
`;
export const Steplist = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 175%;
    color: #1d1d1d;
    @media screen and (max-width: 1060px) {
      font-size: 13px;
    }
  }
  :hover {
  }
  /* width: 473px; */
  /* height: 58px; */

  background: ${(props) => props.f && "#ffffff"};
  box-shadow: ${(props) => props.f && "0px 4px 20px rgba(0, 0, 0, 0.1)"};
  border-radius: ${(props) => props.f && "16px"};
  padding: 0 16px;
  padding-top: ${(props) => props.f && "10px"};
  padding-bottom: ${(props) => props.f && "10px"};
`;
export const StepCircle = styled.div`
  background: #ccdfff;
  min-width: 34px;
  min-height: 34px;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StepLine = styled.div`
  width: 100%;
  margin: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
export const StepSelect = styled.div`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background: #005dfe;
  position: relative;
`;
export const StepSelectOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: #ccdfff;
  border-radius: 50%;
  position: absolute;
  left: -9px;
  top: ${(props) => props.f == 0 && "0px"};
  top: ${(props) => props.f == 1 && "65px"};
  top: ${(props) => props.f == 2 && "130px"};
  top: ${(props) => props.f == 3 && "195px"};
  top: ${(props) => props.f == 4 && "265px"};
  top: ${(props) => props.f == 5 && "330px"};
  top: ${(props) => props.f == 6 && "400px"};
  top: ${(props) => props.f == 7 && "465px"};
  top: ${(props) => props.f == 8 && "auto"};
  bottom: ${(props) => props.f == 8 && "0px"};
  // 0 => 0px , 1 => 65px, 2 => 130px 3=> 195px 4 => 265px 5 => 330px 6 => 400px 7 => 465px
  @media screen and (max-width: 590px) {
    top: ${(props) => props.f == 0 && "0px"};
    top: ${(props) => props.f == 1 && "65px"};
    top: ${(props) => props.f == 2 && "130px"};
    top: ${(props) => props.f == 3 && "200px"};
    top: ${(props) => props.f == 4 && "270px"};
    top: ${(props) => props.f == 5 && "345px"};
    top: ${(props) => props.f == 6 && "425px"};
    top: ${(props) => props.f == 7 && "500px"};
    top: ${(props) => props.f == 8 && "auto"};
    bottom: ${(props) => props.f == 8 && "0px"};
  }
`;
export const StepTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #ffffff;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background: #003899;
  border-radius: 36px;
  position: absolute;
  left: -70px;
  top: -8px;
  @media screen and (max-width: 470px) {
    display: none;
  }
`;
// -------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const SupportDownPart = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 180px;
  @media screen and (max-width: 1360px) {
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
export const SupportDownImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 530px) {
    img {
      width: 90%;
    }
  }
`;
export const SupportDownRight = styled.div`
  display: flex;
  text-align: left;
  margin-left: 120px;
  height: fit-content;
  align-items: center;
  @media screen and (max-width: 1360px) {
    margin-right: 0;
    margin-right: 100px;
    margin-left: 0;
  }
  @media screen and (max-width: 1180px) {
    margin-right: 30px;
  }
  @media screen and (max-width: 960px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
    order: 2;
  }
`;
export const TabsDownPart = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  height: 88px;
  background: #005dfe;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: -40px;
  align-items: center;
  padding: 0 80px;
  width: 100%;
  @media screen and (max-width: 1030px) {
    max-width: 500px;
    padding: 0 30px;
    height: 60px;
    margin-top: -30px;
  }
  @media screen and (max-width: 690px) {
    max-width: 330px;
  }
  @media screen and (max-width: 500px) {
    max-width: 200px;
    height: 50px;
    margin-top: -25px;
  }
`;

export const StepsDownBar = styled.div`
  height: 490px;
  border-left: 1px solid #cccccc;
  margin-right: 30px;
  position: relative;
  @media screen and (max-width: 590px) {
    height: 530px;
    margin-right: 20px;
  }
`;
export const StepDownlists = styled.div`
  @media screen and (max-width: 590px) {
    width: 280px;
  }
`;
export const StepDownlist = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 175%;
    color: #1d1d1d;
    @media screen and (max-width: 1060px) {
      font-size: 13px;
    }
  }
  /* width: 473px; */
  /* height: 58px; */

  background: ${(props) => props.f && "#ffffff"};
  box-shadow: ${(props) => props.f && "0px 4px 20px rgba(0, 0, 0, 0.1)"};
  border-radius: ${(props) => props.f && "16px"};
  padding: 0 16px;
  padding-top: ${(props) => props.f && "10px"};
  padding-bottom: ${(props) => props.f && "10px"};
`;
export const StepDownCircle = styled.div`
  background: #ccdfff;
  min-width: 34px;
  min-height: 34px;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StepDownLine = styled.div`
  width: 100%;
  margin: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
export const StepDownSelect = styled.div`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background: #005dfe;
  position: relative;
`;
export const StepDownSelectOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: #ccdfff;
  border-radius: 50%;
  position: absolute;
  left: -9px;
  top: ${(props) => props.f == 0 && "0px"};
  top: ${(props) => props.f == 1 && "65px"};
  top: ${(props) => props.f == 2 && "135px"};
  top: ${(props) => props.f == 3 && "200px"};
  top: ${(props) => props.f == 4 && "270px"};
  top: ${(props) => props.f == 5 && "335px"};
  top: ${(props) => props.f == 6 && "405px"};
  top: ${(props) => props.f == 7 && "auto"};
  bottom: ${(props) => props.f == 7 && "0px"};
  // 0 => 0px , 1 => 65px, 2 => 130px 3=> 195px 4 => 265px 5 => 330px 6 => 400px 7 => 465px
  @media screen and (max-width: 590px) {
    top: ${(props) => props.f == 0 && "0px"};
    top: ${(props) => props.f == 1 && "70px"};
    top: ${(props) => props.f == 2 && "145px"};
    top: ${(props) => props.f == 3 && "210px"};
    top: ${(props) => props.f == 4 && "285px"};
    top: ${(props) => props.f == 5 && "360px"};
    top: ${(props) => props.f == 6 && "440px"};
    top: ${(props) => props.f == 7 && "auto"};
    bottom: ${(props) => props.f == 7 && "0px"};
  }
`;
export const StepDownTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #ffffff;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background: #003899;
  border-radius: 36px;
  position: absolute;
  left: -70px;
  top: -8px;
  @media screen and (max-width: 1190px) {
    display: none;
  }
  @media screen and (max-width: 960px) {
    display: flex;
  }
  @media screen and (max-width: 470px) {
    display: none;
  }
`;
// =---------------------------------------------------------------------------

export const CarPartOut = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
  width: 100%;
  @media screen and (max-width: 1240px) {
    align-items: center;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 920px) {
    height: auto;
  }
`;
export const CarImg = styled.img`
  @media screen and (max-width: 660px) {
    width: 90%;
  }
`;
export const CarPart = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 120px;
  @media screen and (max-width: 1480px) {
    margin-right: 0;
  }
  @media screen and (max-width: 490px) {
    align-items: center;
  }
`;
export const CarContent = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 125%;
  color: #1d1d1d;
  max-width: 487px;
  text-align: left;
  @media screen and (max-width: 1020px) {
    font-size: 25px;
    max-width: 400px;
  }
  @media screen and (max-width: 1000px) {
    text-align: center;
  }
  @media screen and (max-width: 490px) {
    font-size: 18px;
    max-width: 90%;
  }
`;
export const CarBtns = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 64px;
  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 500px) {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
`;
export const CarLearnBtn = styled.div`
  font-family: JakartaBold;
  font-size: 16px;
  cursor: pointer;
  line-height: 175%;
  color: #005dfe;

  width: 135px;
  height: 44px;
  border: 2px solid #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 400px) {
    width: 100px;
    height: 35px;
    font-size: 12px;
  }
`;
export const CarStartBrn = styled.div`
  font-family: JakartaBold;
  font-size: 16px;
  line-height: 175%;
  color: #ffffff;
  cursor: pointer;
  width: 201px;
  height: 44px;
  background: #005dfe;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  .car-start-btn {
    margin-left: 9px;
  }
  @media screen and (max-width: 400px) {
    width: 160px;
    height: 35px;
    font-size: 12px;
  }
`;
export const CarPartC = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  height: 100%;

  @media screen and (max-width: 1480px) {
    width: calc(100% - 60px);
    padding: 0 60px;
  }
  @media screen and (max-width: 1180px) {
    gap: 30px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const InterMain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 0;
  img {
    @media screen and (max-width: 1460px) {
      width: 1100px;
    }
    @media screen and (max-width: 1100px) {
      width: 800px;
    }
    @media screen and (max-width: 800px) {
      width: 500px;
    }
    @media screen and (max-width: 520px) {
      width: 90%;
    }
  }
  @media screen and (max-width: 520px) {
    padding-top: 50px;
  }
`;
export const InterTitle = styled.div`
  font-style: normal;
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;

  @media screen and (max-width: 800px) {
    font-size: 25px;
  }
`;
export const InterSub = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  margin-top: 24px;
  text-align: center;
  color: #4d4d4d;
  @media screen and (max-width: 800px) {
    width: 90%;
  }
`;
export const TabTitle = styled.div`
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  font-family: JakartaBold;
  max-width: 800px;
  margin: 50px 0 100px;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 850px) {
    font-size: 25px;
    line-height: 35px;
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    margin: 0 0 50px;
    font-size: 20px;
    line-height: 25px;
  }
`;
