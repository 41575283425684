import React, { useState, useEffect } from "react";

// @import styles
import {
  HomeContainer,
  HomeMain,
  FleetPart,
  FleetLeft,
  FleetRight,
  FSubTitle,
  FTitle,
  FContent,
  FBtn,
  PlatFormPart,
  TabsPart,
  PlatMain,
  PlatLeft,
  PlatFormMain,
  PlatRight,
  OneTab,
  PlatTitle,
  PlatItemPart,
  PlatLearnBtn,
  PlatRow,
  PlatItem,
  PlatItemImg,
  PlatFormItems,
  TabLine,
  RedefinedPart,
  RedefinedTitle,
  RedefinedImg,
  QuotePart,
  QuoteMain,
  QuoteContent,
  QuoteCEO,
  QuoteImg,
  TrackPart,
  TrackLeft,
  TrackRight,
  TrackMain,
  TrackBtns,
  TrackLearnBtn,
  TrackBtn,
  LearnBtnOut,
  QuoteContentOut,
  TrackTitle,
  TrackContent,
  RedLeftCircle,
  RedRightCircle,
  LCircleTitle,
  LCircleLists,
  LCircleList,
  PlatFormRow,
  CircleWrapper,
} from "./home.style";
import { BsArrowRight, BsCheck2 } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { StrapiAPI } from "../../actions/config";
import axios from "axios";
import { Link } from "react-router-dom";
// @import resource

const HomeComponent = () => {
  const [tmsData, setTmsData] = useState();
  const [eldData, setEldData] = useState();
  const [dashData, setDashData] = useState();
  const [flag, setFlag] = useState(0);
  const [data, setData] = useState();
  const [commonData, setCommonData] = useState([]);

  const fetchData = () => {
    axios.get(`${StrapiAPI}/homes`).then((home) => {
      if (home) {
        setTmsData({
          title: "TMS",
          url:
            StrapiAPI + home.data.filter((e) => e.name == "tms")[0]?.media?.url,
          bTitle:
            "Unified TMS Solution with the Power of Artificial Intelligence.",
          text1: "AI powered work order entry",
          text2: "Transparent fleet visibility",
          text3: "Automated dispatching & accounting",
          text4: "Automated document management",
          text5: "Safety & compliance seamlessly monitored",
          text6: "Easily manage account payables, receivables.",
          text7:
            "Intuitive mobile application for drivers, owner-operators, & carriers",
        });
        setData({
          title: "TMS",
          url:
            StrapiAPI + home.data.filter((e) => e.name == "tms")[0]?.media?.url,
          bTitle:
            "Unified TMS Solution with the Power of Artificial Intelligence.",
          text1: "AI powered work order entry",
          text2: "Transparent fleet visibility",
          text3: "Automated dispatching & accounting",
          text4: "Automated document management",
          text5: "Safety & compliance seamlessly monitored",
          text6: "Easily manage account payables, receivables.",
          text7:
            "Intuitive mobile application for drivers, owner-operators, & carriers",
        });
        setEldData({
          title: "ELD",
          url:
            StrapiAPI + home.data.filter((e) => e.name == "eld")[0]?.media?.url,
          bTitle:
            "Advanced ELDs to help automate and ensure compliance with ELD regulations. ",
          text1: "FMCSA certified ELD",
          text2: "Hardwired or bluetooth enabled ELD",
          text3: "Real-time log management",
          text4: "Real-time log management",
          text5: "IFTA reporting",
          text6: "Real-time vehicle diagnostics",
          text7: "Schedule maintenance",
        });
        setDashData({
          title: "DASH CAM",
          url:
            StrapiAPI +
            home.data.filter((e) => e.name == "dashcam")[0]?.media?.url,
          bTitle:
            "High-quality AI onboard dash cameras provides exceptional video telematics.",
          text1: "AI powered fleet safety solution",
          text2: "Driver scorecard reporting & compliance trends",
          text3: "In cab driver assistance",
          text4: "DVR and time-lapse footage requests",
          text5: "Mitigate accident risks",
          text6: "Driver coatching workflow",
          text7: "Video telematics & live streaming",
          text7: "Positive driver recognition",
        });
      }
    });
    axios.get(`${StrapiAPI}/commons`).then((awako) => {
      if (awako) {
        setCommonData(awako.data);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const changeTab = (e) => {
    setFlag(e);
  };

  return (
    <HomeContainer>
      <HomeMain>
        <FleetPart>
          <FleetLeft>
            <FSubTitle>FLEET MANAGEMENT POWERED BY AI TECHNOLOGY</FSubTitle>
            <FTitle>
              Awako - <span>Powering</span> the Future of Logistics
            </FTitle>
            <FContent>
              Awako provides an AI-powered TMS  that is an all-in-one platform
              for dispatch, accounting, ELD, dash cam, telematics, professional
              back office support, safety & compliance audits
            </FContent>
            <Link to="/contact">
              <FBtn>
                <span>Get started now</span>
                <BsArrowRight className="arrow-right-class" />
              </FBtn>
            </Link>
          </FleetLeft>
          <FleetRight>
            <video
              width="100%"
              height="100%"
              autoPlay
              loop
              muted
              key={
                commonData &&
                StrapiAPI +
                  commonData.filter((e) => e.name == "movie")[0]?.media?.url
              }
            >
              <source
                src={
                  commonData &&
                  StrapiAPI +
                    commonData.filter((e) => e.name == "movie")[0]?.media?.url
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </FleetRight>
        </FleetPart>
        <PlatFormPart>
          <PlatFormMain>
            <TabsPart>
              <OneTab
                select={flag == 0 && true}
                onClick={(e) => {
                  changeTab(0);
                  setData(tmsData);
                }}
              >
                TMS
              </OneTab>
              <TabLine />
              <OneTab
                select={flag == 1 && true}
                onClick={(e) => {
                  changeTab(1);
                  setData(eldData);
                }}
              >
                ELD
              </OneTab>
              <TabLine />
              <OneTab
                select={flag == 2 && true}
                onClick={(e) => {
                  changeTab(2);
                  setData(dashData);
                }}
              >
                Dash Cam
              </OneTab>
            </TabsPart>
            <PlatMain>
              <PlatLeft src={data && data.url} alt="" draggable={false} />
              <PlatRight>
                <PlatTitle>
                  <span>{data && data.title}</span>
                  {data && data.bTitle}
                </PlatTitle>
                <PlatItemPart>
                  <PlatFormItems>
                    <PlatFormRow>
                      <PlatItem>
                        <PlatItemImg
                          src="/assets/image/platIcon1.png"
                          alt=""
                          draggable={false}
                        />
                        <span>{data && data.text1}</span>
                      </PlatItem>
                      <PlatItem w="234px">
                        <PlatItemImg
                          src="/assets/image/platIcon2.png"
                          alt=""
                          draggable={false}
                        />
                        <span>{data && data.text2}</span>
                      </PlatItem>
                    </PlatFormRow>
                    <PlatFormRow>
                      <PlatItem>
                        <PlatItemImg
                          src="/assets/image/platIcon3.png"
                          alt=""
                          draggable={false}
                        />
                        <span>{data && data.text3}</span>
                      </PlatItem>
                      <PlatItem w="234px">
                        <PlatItemImg
                          src="/assets/image/platIcon4.png"
                          alt=""
                          draggable={false}
                        />
                        <span>{data && data.text4}</span>
                      </PlatItem>
                    </PlatFormRow>
                    <PlatFormRow>
                      <PlatItem>
                        <PlatItemImg
                          src="/assets/image/platIcon5.png"
                          alt=""
                          draggable={false}
                        />
                        <span>{data && data.text5}</span>
                      </PlatItem>
                      <PlatItem w="234px">
                        <PlatItemImg
                          src="/assets/image/platIcon6.png"
                          alt=""
                          draggable={false}
                        />
                        <span>{data && data.text6}</span>
                      </PlatItem>
                    </PlatFormRow>
                  </PlatFormItems>
                  <PlatRow>
                    <PlatItem w="90%">
                      <PlatItemImg
                        src="/assets/image/platIcon7.png"
                        alt=""
                        draggable={false}
                      />
                      <span>{data && data.text7}</span>
                    </PlatItem>
                  </PlatRow>
                  <LearnBtnOut>
                    {/* <PlatLearnBtn>
                      Learn More <BsArrowRight />
                    </PlatLearnBtn> */}
                  </LearnBtnOut>
                </PlatItemPart>
              </PlatRight>
            </PlatMain>
          </PlatFormMain>
        </PlatFormPart>
        <RedefinedPart>
          <RedefinedTitle>
            Awako - Transportation Management <span>Redefined</span>
          </RedefinedTitle>
          <CircleWrapper>
            <RedLeftCircle>
              <LCircleTitle>Old Ways</LCircleTitle>
              <LCircleLists>
                <LCircleList>
                  <IoMdClose className="way-icon" />
                  Outdated Technology
                </LCircleList>
                <LCircleList>
                  <IoMdClose className="way-icon" />
                  No Tracking System
                </LCircleList>
                <LCircleList>
                  <IoMdClose className="way-icon" />
                  Shortage of Drivers
                </LCircleList>
                <LCircleList>
                  <IoMdClose className="way-icon" />
                  Poor Routing Strategies
                </LCircleList>
                <LCircleList>
                  <IoMdClose className="way-icon" />
                  Lack of Efficiency
                </LCircleList>
              </LCircleLists>
            </RedLeftCircle>
            <RedRightCircle>
              <LCircleTitle f={true}>New Ways</LCircleTitle>
              <LCircleLists>
                <LCircleList f={true}>
                  <BsCheck2 className="way-icon" />
                  Easy to navigate
                </LCircleList>
                <LCircleList f={true}>
                  <BsCheck2 className="way-icon" />
                  Secured Driver Availability
                </LCircleList>
                <LCircleList f={true}>
                  <BsCheck2 className="way-icon" />
                  Real-Time fleet visibility
                </LCircleList>
                <LCircleList f={true}>
                  <BsCheck2 className="way-icon" />
                  Reliable Scheduling
                </LCircleList>
                <LCircleList f={true}>
                  <BsCheck2 className="way-icon" />
                  Centralized Platform
                </LCircleList>
              </LCircleLists>
            </RedRightCircle>
          </CircleWrapper>
        </RedefinedPart>
        <QuotePart>
          <QuoteMain>
            <QuoteContentOut>
              <QuoteContent>
                <QuoteImg
                  src="/assets/image/quote.png"
                  alt=""
                  draggable={false}
                />
                Awako exists to help empower trucking companies with the proper
                tools, technology, and support. This allows them to not only
                better manage their current workload, but also expand without
                having to hire or add costly hardware. Our team is dedicated to
                providing high-quality products and services on a consistent and
                trustworthy basis. We are your technology partner… for the long
                haul. We grow together.
              </QuoteContent>

              <QuoteCEO>- Quote from the CEO</QuoteCEO>
            </QuoteContentOut>
          </QuoteMain>
        </QuotePart>
        <TrackPart>
          <TrackMain>
            <TrackLeft>
              <TrackTitle>
                Let us Show You The Future of <span>Fleet Management</span>
              </TrackTitle>
              <TrackContent>
                Awako Delivers an unparalleled Fleet Management Solution with
                the Power of AI All Under One Umbrella.
              </TrackContent>
              {/* <TrackBtns>
                <TrackLearnBtn>Learn more</TrackLearnBtn>
                <TrackBtn>
                  <span>Get started now</span>
                  <BsArrowRight className="arrow-right-class" />
                </TrackBtn>
              </TrackBtns> */}
            </TrackLeft>
            <TrackRight
              src={
                commonData &&
                StrapiAPI +
                  commonData.filter((e) => e.name == "trans")[0]?.media?.url
              }
              alt=""
              draggable={false}
            />
          </TrackMain>
        </TrackPart>
      </HomeMain>
    </HomeContainer>
  );
};

export default HomeComponent;
