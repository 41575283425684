import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @import styles
import {
  HeaderContainer,
  HeaderMain,
  LogoPart,
  LogoImg,
  ContactBtn,
  FooterTop,
  ProductPart,
  PItem,
  PSubItem,
  Ptitle,
} from "./footer.style";
// @import resource

const FooterComponent = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeaderMain>
        <FooterTop>
          <LogoPart>
            <LogoImg
              src="/assets/image/footer-logo.png"
              alt=""
              draggable={false}
            />
            <span>
              Awako provides an AI-powered TMS that is an all-in-one platform
              for dispatch, accounting, ELD, dash cams, safety & compliance
              audit support, and professional back office services. Empowering
              trucking companies with powerful tools to drive their business to
              the next level.
            </span>
          </LogoPart>
          <ProductPart>
            <PItem>
              <Ptitle>Products</Ptitle>
              <PSubItem>
                <span onClick={() => navigate("/tms")}>TMS</span>
                <span onClick={() => navigate("/eld")}>ELD</span>
                <span onClick={() => navigate("/dashcams")}>Dash Cams</span>
              </PSubItem>
            </PItem>
            <PItem>
              <Ptitle>Links</Ptitle>
              <PSubItem>
                <span onClick={() => navigate("/services")}>Services</span>
                <span onClick={() => navigate("/company")}>Company</span>
                <span onClick={() => navigate("/contact")}>Contact</span>
                <span>Blogs & Latest News</span>
              </PSubItem>
            </PItem>
            {/* <PItem>
              <Ptitle>Resources</Ptitle>
              <PSubItem>
                <span>Customer Stories</span>
                <span>Support Center</span>
                <span>Guides</span>
                <span>Brand Assets</span>
              </PSubItem>
            </PItem> */}
          </ProductPart>
        </FooterTop>
        <ContactBtn>© 2023 Awako | All rights reserved</ContactBtn>
      </HeaderMain>
    </HeaderContainer>
  );
};

export default FooterComponent;
