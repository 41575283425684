import styled from "styled-components";

export const HeaderContainer = styled.div`
  height: 111.47px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HeaderMain = styled.div`
  max-width: 1440px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .mobile-bar {
    display: none;
    cursor: pointer;
    @media screen and (max-width: 1100px) {
      display: flex;
      font-size: 20px;
    }
  }
`;

export const LogoPart = styled.div`
  margin-left: 100px;
  @media screen and (max-width: 1250px) {
    margin-left: 30px;
  }
`;
export const LogoImg = styled.img`
  cursor: pointer;
  @media screen and (max-width: 800px) {
    width: 150px;
  }
  @media screen and (max-width: 500px) {
    width: 120px;
  }
`;
export const SubMenuPart = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  z-index: 1000;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 70px;
    right: 20px;
    padding: 20px;
    gap: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: ${(props) => (props.flag ? "flex" : "none")};
  }
`;
export const ContactBtn = styled.div`
  width: 157px;
  height: 37px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.1em;
  color: #ffffff;

  background: #005dfe;
  border-radius: 64px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
  @media screen and (max-width: 500px) {
    width: 100px;
    height: 35px;
    font-size: 8px;
  }
`;

export const SubItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  span {
    font-size: 12px;
    line-height: 175%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #1d1d1d;
    margin-right: 11px;
    :hover {
      font-weight: 800px;
    }
  }
`;
export const SubSubMenu = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  /* @media screen and (max-width: 1100px) {
    left: ${(props) => (props.f ? "-150px" : "-150px")};
    top: ${(props) => (props.f ? "50px" : "0")};
  } */
`;
export const SSItem = styled.div`
  font-size: 12px;
  line-height: 175%;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color: #1d1d1d;
  white-space: nowrap;
  margin-right: 11px;
  :hover {
    color: #005dfe;
    font-weight: 700;
  }
`;
export const DownItem = styled.div`
  display: flex;
  align-items: center;
  :hover {
    font-weight: 800;
  }
`;

export const SubMenuWrapper = styled.div`
  position: absolute;
  padding-top: 30px;
  top: 0;
  left: 0;
  @media screen and (max-width: 1100px) {
    padding-top: 0px;
    padding-right: 130px;
    right: 0;
    top: 0;
    left: auto;
  }
`;
export const LoginCircle = styled.div`
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #005dfe;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
  @media screen and (max-width: 500px) {
    font-size: 17px;
    width: 35px;
    height: 35px;
  }
`;
export const HRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
  gap: 10px;
  a {
    text-decoration: none;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 175%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    cursor: pointer;
    color: #005dfe;
    margin-right: 10px;
  }
  @media screen and (max-width: 1250px) {
    margin-right: 30px;
  }
  @media screen and (max-width: 500px) {
    gap: 5px;
  }
`;
