import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from "./hooks/scroltop";

import Header from "./layout/Header";
import Footer from "./layout/Footer";

import Home from "./components/Home";
import ELD from "./components/ELD";
import DashCams from "./components/DashCams";
import Services from "./components/Services";
import Company from "./components/Company";
import Contact from "./components/Contact";
import TMS from "./components/TMS";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Header />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/eld" element={<ELD />}></Route>
            <Route path="/dashcams" element={<DashCams />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/company" element={<Company />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/tms" element={<TMS />}></Route>
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
