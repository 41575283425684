import React, { useState, useEffect } from "react";
import axios from "axios";
import emailjs from "emailjs-com";

// @import styles
import {
  ComContainer,
  DediPartOut,
  DediPart,
  DediLeft,
  DediRight,
  DediTitle,
  DediContent,
  AwakoTitle,
  OfficePart,
  OfficeLeft,
  OfficeText,
  Off,
  Info,
  InfoItem,
  OfficeMiddle,
  OfficeRight,
  CusNameInput,
  CusTextArea,
  ButtonCTA,
  DediSubT,
} from "./contact.style";

import { StrapiAPI } from "../../actions/config";

const CompanyComponent = () => {
  const [strapiData, setStrapiData] = useState([]);
  const [sendData, setSendData] = useState({
    name: "",
    email: "",
    subject: "",
    content: "",
  });

  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const SERVICE_ID = "service_cqgww0s";
  const TEMPLATE_ID = "template_wpmbz9v";
  const PUBLIC_KEY = "IzDFfY7PMbrN7XoyX";

  useEffect(() => {
    axios.get(`${StrapiAPI}/contacts`).then((awako) => {
      if (awako) {
        setStrapiData(awako.data);
      }
    });
  }, []);

  // Validation function
  const validateFields = () => {
    const newErrors = {};
    if (!sendData.name.trim()) newErrors.name = "Name is required.";
    if (!sendData.email.trim()) newErrors.email = "Email is required.";
    if (!sendData.subject.trim()) newErrors.subject = "Mobile is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Send email using EmailJS
  const sendMail = () => {
    if (!validateFields()) return; // Stop submission if validation fails

    const templateParams = {
      from_name: sendData.name,
      from_email: sendData.email,
      subject: sendData.subject,
      message: sendData.content,
    };

    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)
      .then(() => {
        setShowPopup(true);
        setSendData({ name: "", email: "", subject: "", content: "" });
        setErrors({}); // Clear errors after successful submission
      })
      .catch((error) => console.error("Email sending failed...", error));
  };

  return (
    <ComContainer>
      <DediPartOut>
        <DediPart>
          <DediLeft
            f={
              strapiData &&
              StrapiAPI +
                strapiData.filter((e) => e.name === "contact")[0]?.media?.url
            }
          />
          <DediRight>
            <DediSubT>CONTACT US</DediSubT>
            <DediTitle>
              Awako is Here for the <span>Road Ahead!</span>
            </DediTitle>
            <DediContent>
              We are your partner and our scalable system will keep you equipped
              now and into the future, whether you have 10 or 10,000 trucks.
            </DediContent>
          </DediRight>
        </DediPart>
      </DediPartOut>

      <AwakoTitle>Awako is here to help in any way we can.</AwakoTitle>
      <OfficePart>
        <OfficeLeft>
          <OfficeText>
            Contact us with any questions and let our experts help!
          </OfficeText>
          <Off>Office</Off>
          <Info>
            <InfoItem>
              <img src="/assets/image/phone.png" alt="" />
              <span>+1 (916) 378 4747, +1 (855) 335 2682</span>
            </InfoItem>
            <InfoItem>
              <img src="/assets/image/mail.png" alt="" />
              <span>support@awako.ai</span>
            </InfoItem>
            <InfoItem>
              <img src="/assets/image/map-pin.png" alt="" />
              <span>5701 Lonetree blvd, 222 Rocklin CA 95765</span>
            </InfoItem>
          </Info>
        </OfficeLeft>
        <OfficeMiddle />
        <OfficeRight>
          <CusNameInput
            type="text"
            placeholder="Name"
            value={sendData.name}
            onChange={(e) => setSendData({ ...sendData, name: e.target.value })}
          />
          {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}

          <CusNameInput
            type="email"
            placeholder="Email"
            value={sendData.email}
            onChange={(e) => setSendData({ ...sendData, email: e.target.value })}
          />
          {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}

          <CusNameInput
            type="text"
            placeholder="Mobile"
            value={sendData.subject}
            onChange={(e) =>
              setSendData({ ...sendData, subject: e.target.value })
            }
          />
          {errors.subject && <p style={{ color: "red" }}>{errors.subject}</p>}

          <CusTextArea
            placeholder="Message..."
            value={sendData.content}
            onChange={(e) =>
              setSendData({ ...sendData, content: e.target.value })
            }
          />

          <ButtonCTA onClick={sendMail}>Submit</ButtonCTA>
        </OfficeRight>
      </OfficePart>

      {/* POP-UP OVERLAY */}
      {showPopup && (
        <div style={styles.overlay}>
          <div style={styles.popup}>
            <h3 style={{ marginBottom: "1rem" }}>Thank you!</h3>
            <p style={{ marginBottom: "1.5rem" }}>
              Thanks for contacting us. We will get in touch with you soon!
            </p>
            <ButtonCTA onClick={() => setShowPopup(false)}>Close</ButtonCTA>
          </div>
        </div>
      )}
    </ComContainer>
  );
};

// Styles for pop-up
const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
  },
  popup: {
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "8px",
    maxWidth: "90%",
    width: "400px",
    textAlign: "center",
  },
};

export default CompanyComponent;