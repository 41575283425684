import styled from "styled-components";

export const HomeContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HomeMain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const TrackTitle = styled.div`
  font-family: JakartaBold;
  font-weight: 700;
  font-size: 36px;
  position: relative;
  max-width: 570px;
  line-height: 125%;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 1100px) {
    margin-top: 30px;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
  @media screen and (max-width: 420px) {
    font-size: 20px;
    width: 225px;
  }
`;
export const TrackPart = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 1100px) {
    height: auto;
  }
`;
export const TrackLeft = styled.div`
  margin-left: 120px;
  text-align: left;
  img {
    display: none;
    @media screen and (max-width: 1100px) {
      display: flex;
      width: 500px;
    }
    @media screen and (max-width: 600px) {
      width: 80%;
    }
  }
  @media screen and (max-width: 1250px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    align-items: center;
  }
`;
export const TrackRight = styled.img`
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
export const TrackMain = styled.div`
  width: 1440px;
  display: flex;
  padding: 80px 0;
  height: 500px;
  z-index: 2;
  background: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  @media screen and (max-width: 1190px) {
    background-size: cover;
    background-position: left;
  }
  @media screen and (max-width: 1100px) {
    justify-content: center;
    padding: 40px 0 80px;
  }
  @media screen and (max-width: 700px) {
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 0 40px;
  }
`;
export const LogPartOut = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #f0f1f8;
  padding: 100px 0;
  @media screen and (max-width: 500px) {
    padding: 50px 0;
  }
`;
export const LogPart = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;
export const LogSub = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #005dfe;
  width: 90%;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;
export const LogTitle = styled.div`
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  position: relative;
  text-align: center;
  letter-spacing: -0.02em;
  max-width: 780px;
  margin: 8px 0 24px;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  img {
    position: absolute;
    right: -80px;
    top: -50px;
  }
  @media screen and (max-width: 1030px) {
    width: 500px;
  }
  @media screen and (max-width: 700px) {
    font-size: 30px;
    width: 90%;
    img {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const LogContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #4d4d4d;
  @media screen and (max-width: 800px) {
    width: 90%;
    line-height: 25px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
export const LogImgTag = styled.div`
  width: 1200px;
  height: 374px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d9d9d9;
  border-radius: 24px 24px 0px 0px;
  margin-top: 40px;
  background: url(${(props) => props.f});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 1250px) {
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    height: 250px;
    img {
      width: 50px;
    }
  }
`;
export const HosPart = styled.div`
  background: #e5efff;
  border-radius: 0 0 24px 24px;
  width: 1152px;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 1250px) {
    width: calc(90% - 48px);
  }
  @media screen and (max-width: 950px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
export const HosItem = styled.div`
  /* height: 157px; */
  margin: 34px 24px;
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const HosCircle = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #ccdfff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HosItemText = styled.div`
  font-weight: 600;
  font-family: JakartaBold;
  font-size: 18px;
  line-height: 175%;
  margin-top: 16px;
  color: #1d1d1d;
  text-align: left;
  @media screen and (max-width: 500px) {
    text-align: center;
  }
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
export const HosItemOut = styled.div`
  // 1
  border-right: ${(props) =>
    (props.dNum == 1 || props.dNum == 2 || props.dNum == 3) &&
    "1px solid rgba(0, 0, 0, 0.1)"};
  border-bottom: ${(props) =>
    (props.dNum == 1 || props.dNum == 2 || props.dNum == 3) &&
    "1px solid rgba(0, 0, 0, 0.1)"};
  //4
  border-bottom: ${(props) =>
    props.dNum == 4 && "1px solid rgba(0, 0, 0, 0.1)"};
  //5
  border-right: ${(props) =>
    (props.dNum == 5 || props.dNum == 6 || props.dNum == 7) &&
    "1px solid rgba(0, 0, 0, 0.1)"};
  @media screen and (max-width: 950px) {
    border-right: ${(props) =>
      props.dNum == 4 ||
      props.dNum == 5 ||
      props.dNum == 7 ||
      props.dNum == 8 ||
      props.dNum == 1 ||
      props.dNum == 2
        ? "1px solid rgba(0, 0, 0, 0.1)"
        : "none"};
    border-bottom: ${(props) =>
      (props.dNum == 4 ||
        props.dNum == 5 ||
        props.dNum == 3 ||
        props.dNum == 6) &&
      "1px solid rgba(0, 0, 0, 0.1)"};
  }
  @media screen and (max-width: 650px) {
    border-right: ${(props) =>
      props.dNum == 3 || props.dNum == 5 || props.dNum == 7 || props.dNum == 1
        ? "1px solid rgba(0, 0, 0, 0.1)"
        : "none"};
  }
  @media screen and (max-width: 500px) {
    border-bottom: ${(props) =>
      (props.dNum == 1 ||
        props.dNum == 2 ||
        props.dNum == 3 ||
        props.dNum == 5 ||
        props.dNum == 6 ||
        props.dNum == 7 ||
        props.dNum == 4) &&
      "1px solid rgba(0, 0, 0, 0.1)"};
    border-right: ${(props) =>
      (props.dNum == 1 ||
        props.dNum == 3 ||
        props.dNum == 5 ||
        props.dNum == 7) &&
      "none"};
  }
`;
export const TrackSubWrapper = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #005dfe;
`;
export const TrackContentWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #4d4d4d;
  margin-top: 24px;
  max-width: 445px;
  @media screen and (max-width: 700px) {
    text-align: center;
    width: 90%;
    line-height: 180%;
  }
`;
