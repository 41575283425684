import React, { useState, useEffect } from "react";
import axios from "axios";
// @import styles
import {
  HomeContainer,
  HomeMain,
  FleetPart,
  FleetLeft,
  FTitle,
  FContent,
  FleetRight,
  FSubtotle,
  LogPartOut,
  LogPart,
  SupportPart,
  SupportImg,
  SupportRight,
  OneTab,
  TabLine,
  TabsPart,
  StepsBar,
  Steplists,
  Steplist,
  StepCircle,
  StepLine,
  StepSelect,
  StepSelectOut,
  StepTitle,
  //
  SupportDownPart,
  SupportDownImg,
  SupportDownRight,
  StepsDownBar,
  StepDownlists,
  StepDownlist,
  StepDownCircle,
  StepDownLine,
  StepDownSelect,
  StepDownSelectOut,
  StepDownTitle,
  CarPartOut,
  CarImg,
  CarPartC,
  CarPart,
  CarContent,
  CarBtns,
  CarLearnBtn,
  CarStartBrn,
  FSubTitle,
  InterMain,
  InterTitle,
  InterSub,
  TabTitle,
} from "./tms.style";
import { StrapiAPI } from "../../actions/config";
import { BsArrowRight } from "react-icons/bs";
// @import resource

const ELDComponent = () => {
  const [tselect, setTSelect] = useState(0);
  const [rselect, setRSelect] = useState(0);
  const [flag, setFlag] = useState(0);
  const [hoverFlag, setHoverFlag] = useState(false);
  const [hoverSelect, setHoverSelect] = useState(0);
  // -------------------------------------
  const [rhoverFlag, setrHoverFlag] = useState(false);
  const [rhoverSelect, setrHoverSelect] = useState(0);

  const [strapiData, setStrapiData] = useState();
  const [commonData, setCommonData] = useState();

  const [carrierData, setCarrierData] = useState();
  const [brokerData, setBrokerData] = useState();

  const fetchData = () => {
    axios.get(`${StrapiAPI}/tms-products`).then((tms) => {
      if (tms) {
        setStrapiData(tms.data);
        setCarrierData([
          {
            url: "ico1.png",
            text: "AI powered work order entry",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "tms-worker")[0]?.media?.url,
          },
          {
            url: "Vector.png",
            text: "Driver app",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "c-driver")[0]?.media?.url,
          },
          {
            url: "ico6.png",
            text: "Auto-geofencing",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "c-geo")[0]?.media?.url,
          },
          {
            url: "ico5.png",
            text: "Real-time fleet visibility",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "c-realtime")[0]?.media?.url,
          },
          {
            url: "ico0.png",
            text: "Automated document management",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "c-document")[0]?.media?.url,
          },
          {
            url: "ico2.png",
            text: "Easy to send Invoice to customers / factor",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "c-invoice")[0]?.media?.url,
          },
          {
            url: "ico4.png",
            text: "Easy to manage Account payable and receivables",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "c-payable")[0]?.media?.url,
          },
          {
            url: "ico9.png",
            text: "Safety and compliance",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "c-safety")[0]?.media?.url,
          },
          {
            url: "ico8.png",
            text: "Integration partners",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "c-integration")[0]?.media?.url,
          },
        ]);
        setBrokerData([
          {
            url: "ico1.png",
            text: "AI powered work order entry",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "tms-worker")[0]?.media?.url,
          },
          {
            url: "doc.png",
            text: "Automatic quotes and rate analysis",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "b-quote")[0]?.media?.url,
          },
          {
            url: "mobile.png",
            text: "Mobile load board application",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "b-mobile")[0]?.media?.url,
          },
          {
            url: "ico9.png",
            text: "Seamless posting to load boards",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "b-seam")[0]?.media?.url,
          },
          {
            url: "tv.png",
            text: "Carrier onboarding and monitoring",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "b-monitoring")[0]?.media?.url,
          },
          {
            url: "ico0.png",
            text: "Automated document management",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "b-document")[0]?.media?.url,
          },
          {
            url: "ico4.png",
            text: "Easy to manage Account payable and receivables",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "b-payable")[0]?.media?.url,
          },
          {
            url: "ico8.png",
            text: "Integration partners",
            img:
              StrapiAPI +
              tms.data.filter((e) => e.name == "c-integration")[0]?.media?.url,
          },
        ]);
      }
    });
    axios.get(`${StrapiAPI}/commons`).then((awako) => {
      if (awako) {
        setCommonData(awako.data);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const changeTab = (e) => {
    setFlag(e);
  };
  const changeTSelect = (e) => {
    setTSelect(e);
  };
  const changeRSelect = (e) => {
    setRSelect(e);
  };
  return (
    <HomeContainer>
      <HomeMain>
        <FleetPart>
          <FleetLeft>
            <FSubTitle>TRUCKing Management Software (TMS)</FSubTitle>
            <FTitle>
              A <span>Unified TMS Solution</span> with the Power of Artificial
              Intelligence.
            </FTitle>
            <FContent>
              Our TMS helps you manage your day-to-day operations with more
              efficiency.
            </FContent>
          </FleetLeft>
          <FleetRight>
            <video
              width="100%"
              height="100%"
              autoPlay
              loop
              muted
              key={
                commonData &&
                StrapiAPI +
                  commonData.filter((e) => e.name == "movie")[0]?.media?.url
              }
            >
              <source
                src={
                  commonData &&
                  StrapiAPI +
                    commonData.filter((e) => e.name == "movie")[0]?.media?.url
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </FleetRight>
        </FleetPart>

        <LogPartOut>
          <LogPart>
            <TabsPart>
              <OneTab select={flag == 0 && true} onClick={(e) => changeTab(0)}>
                Carrier
              </OneTab>
              <TabLine />
              <OneTab select={flag == 1 && true} onClick={(e) => changeTab(1)}>
                Broker
              </OneTab>
            </TabsPart>
            <TabTitle>
              {flag == 0 ? (
                <>
                  Equip your fleet with the <span>necessary tools</span> to make
                  deliveries promptly and safely
                </>
              ) : (
                <>
                  Provide <span>state-of-the-art</span> systems to manage and
                  arrange the movement of cargo
                </>
              )}
            </TabTitle>
            {flag == 0 && (
              <SupportPart>
                <SupportImg>
                  <img
                    src={carrierData && carrierData[tselect].img}
                    alt=""
                    draggable={false}
                  />
                </SupportImg>
                <SupportRight>
                  <StepsBar>
                    <StepSelectOut f={tselect}>
                      <StepSelect>
                        {(hoverFlag || tselect != hoverSelect) && (
                          <StepTitle>Step {tselect + 1}</StepTitle>
                        )}
                      </StepSelect>
                    </StepSelectOut>
                  </StepsBar>
                  <Steplists>
                    {carrierData &&
                      carrierData.map((e, i) => (
                        <>
                          <Steplist
                            f={tselect == i && true}
                            onClick={() => changeTSelect(i)}
                            onMouseEnter={() => {
                              setHoverFlag(false);
                              setHoverSelect(i);
                            }}
                            onMouseLeave={() => {
                              setHoverFlag(true);
                            }}
                          >
                            <StepCircle>
                              <img
                                src={`/assets/image/tmsSteps/${e.url}`}
                                alt=""
                              />
                            </StepCircle>
                            <span>{e.text}</span>
                          </Steplist>
                          {i < carrierData.length - 1 && <StepLine />}
                        </>
                      ))}
                  </Steplists>
                </SupportRight>
              </SupportPart>
            )}
            {flag == 1 && (
              <SupportDownPart>
                <SupportDownRight>
                  <StepsDownBar>
                    <StepDownSelectOut f={rselect}>
                      <StepDownSelect>
                        {(rhoverFlag || rselect != rhoverSelect) && (
                          <StepTitle>Step {rselect + 1}</StepTitle>
                        )}
                      </StepDownSelect>
                    </StepDownSelectOut>
                  </StepsDownBar>
                  <StepDownlists>
                    {brokerData &&
                      brokerData.map((e, i) => (
                        <>
                          <StepDownlist
                            f={rselect == i && true}
                            onClick={() => changeRSelect(i)}
                            onMouseEnter={() => {
                              setrHoverFlag(false);
                              setrHoverSelect(i);
                            }}
                            onMouseLeave={() => {
                              setrHoverFlag(true);
                            }}
                          >
                            <StepDownCircle>
                              <img
                                src={`/assets/image/tmsSteps/${e.url}`}
                                alt=""
                              />
                            </StepDownCircle>
                            <span>{e.text}</span>
                          </StepDownlist>
                          {i < brokerData.length - 1 && <StepLine />}
                        </>
                      ))}
                  </StepDownlists>
                </SupportDownRight>
                <SupportDownImg>
                  <img
                    src={brokerData && brokerData[rselect].img}
                    alt=""
                    draggable={false}
                  />
                </SupportDownImg>
              </SupportDownPart>
            )}
          </LogPart>
        </LogPartOut>
        <InterMain>
          <InterTitle>Integration Partners</InterTitle>
          <InterSub>
            Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum,
          </InterSub>
          <img
            src={
              strapiData &&
              StrapiAPI +
                strapiData.filter((e) => e.name == "tms-circle")[0]?.media?.url
            }
            alt=""
            draggable={false}
          />
        </InterMain>
        <CarPartOut>
          <CarPartC>
            <CarImg
              src={
                commonData &&
                StrapiAPI +
                  commonData.filter((e) => e.name == "trans")[0]?.media?.url
              }
              alt=""
              draggable={false}
            />
            <CarPart>
              <CarContent>
                Transition your business to experience the true power of a
                single platform to manage your entire operation and let Awako
                take your company into the future of fleet management.
              </CarContent>
              <CarBtns>
                <CarLearnBtn>Learn more</CarLearnBtn>
                <CarStartBrn>
                  Get started now <BsArrowRight className="car-start-btn" />
                </CarStartBrn>
              </CarBtns>
            </CarPart>
          </CarPartC>
        </CarPartOut>
      </HomeMain>
    </HomeContainer>
  );
};

export default ELDComponent;
