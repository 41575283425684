// PrivacyPolicy.js
import React from "react";
// Import your existing styled components
import {
  ComContainer,
} from "../Contact/contact.style"; // Adjust if your style file is located elsewhere
import { ComTitle, ComSubWrapper } from "../Company/company.style";
const PrivacyPolicy = () => {
  return (
    <ComContainer style={{ margin: 0, padding: 0 }}>
      <ComTitle>
        <ComSubWrapper>PRIVACY POLICY</ComSubWrapper>
        Understanding Our <span>Data Practices</span>
      </ComTitle>

      <div style={{ margin: 0, padding: 0, width: "100%" }}>
        <iframe
          src="https://app.termly.io/policy-viewer/policy.html?policyUUID=c12ff17f-586f-43d7-9ebf-689358da1c90"
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
            margin: 0,
            padding: 0,
            display: "block",
          }}
          title="Privacy Policy"
        />
      </div>
    </ComContainer>
  );
};

export default PrivacyPolicy;
