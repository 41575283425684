import React from "react";
// Import your existing styled components
import {
  ComContainer,
} from "../Contact/contact.style";
import { ComTitle, ComSubWrapper } from "../Company/company.style";
const TermsConditions = () => {
  return (
    <ComContainer style={{ margin: 10, padding: 10 }}>
      <ComTitle>
        <ComSubWrapper>TERMS &amp; CONDITIONS</ComSubWrapper>
        Understanding Our <span>Policies &amp; Guidelines</span>
      </ComTitle>      

      <div style={{ margin: 0, padding: 0, width: "100%" }}>
        <iframe
          src="https://app.termly.io/policy-viewer/policy.html?policyUUID=e16e25fd-3de6-4d0a-9696-7bca591e8ce2"
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
            margin: 0,
            padding: 0,
            display: "block",
          }}
          title="Terms and Conditions"
        />
      </div>
    </ComContainer>
  );
};

export default TermsConditions;
