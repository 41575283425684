import React, { useState, useEffect } from "react";

// @import styles
import {
  ComContainer,
  DediPartOut,
  DediPart,
  DediLeft,
  DediRight,
  DediTitle,
  DediContent,
  AwakoTitle,
  OfficePart,
  OfficeLeft,
  OfficeText,
  Off,
  Info,
  InfoItem,
  Socials,
  SItem,
  OfficeMiddle,
  OfficeRight,
  CusNameInput,
  CusTextArea,
  ButtonCTA,
  DediSubT,
} from "./contact.style";
import { StrapiAPI } from "../../actions/config";
import axios from "axios";

// @import resource

const CompanyComponent = () => {
  const [strapiData, setStrapiData] = useState([]);
  const [sendData, setSendData] = useState({});
  const fetchData = () => {
    axios.get(`${StrapiAPI}/contacts`).then((awako) => {
      if (awako) {
        setStrapiData(awako?.data);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const sendMail = () => {
    console.log(sendData);
    axios
      .post("/mail", { sendData })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  return (
    <ComContainer>
      <DediPartOut>
        <DediPart>
          <DediLeft
            f={
              strapiData &&
              StrapiAPI +
                strapiData.filter((e) => e.name == "contact")[0]?.media?.url
            }
          ></DediLeft>
          <DediRight>
            <DediSubT>CONTACT US</DediSubT>
            <DediTitle>
              Awako is Here for the <span>Road Ahead!</span>
            </DediTitle>
            <DediContent>
              We are your partner and our scalable system will keep you equipped
              now and into the future, whether you have 10 or 10,000 trucks.
            </DediContent>
          </DediRight>
        </DediPart>
      </DediPartOut>
      <AwakoTitle>Awako is here to help in any way we can.</AwakoTitle>
      <OfficePart>
        <OfficeLeft>
          <OfficeText>
            Contact us with any questions and let our experts help!
          </OfficeText>
          <Off>Office</Off>
          <Info>
            <InfoItem>
              <img src="/assets/image/phone.png" alt="" />
              <span>+1 (916) 378 4747, +1 (855) 335 2682</span>
            </InfoItem>
            <InfoItem>
              <img src="/assets/image/mail.png" alt="" />
              <span>support@awako.ai</span>
            </InfoItem>
            <InfoItem>
              <img src="/assets/image/map-pin.png" alt="" />
              <span>5701 Lonetree blvd, 222 Rocklin CA 95765</span>
            </InfoItem>
          </Info>
          <Socials>
            <SItem>
              <img src="/assets/image/twitter.png" alt="" />
            </SItem>
            <SItem>
              <img src="/assets/image/instagram.png" alt="" />
            </SItem>
            <SItem>
              <img src="/assets/image/linkedin.png" alt="" />
            </SItem>
          </Socials>
        </OfficeLeft>
        <OfficeMiddle />
        <OfficeRight>
          <CusNameInput
            type="text"
            placeholder="Name"
            value={sendData?.name}
            onChange={(e) => setSendData({ ...sendData, name: e.target.value })}
          />
          <CusNameInput
            type="text"
            placeholder="Email"
            value={sendData?.email}
            onChange={(e) =>
              setSendData({ ...sendData, email: e.target.value })
            }
          />
          <CusNameInput
            type="text"
            placeholder="Subject"
            value={sendData?.subject}
            onChange={(e) =>
              setSendData({ ...sendData, subject: e.target.value })
            }
          />
          <CusTextArea
            value={sendData?.content}
            onChange={(e) =>
              setSendData({ ...sendData, content: e.target.value })
            }
          />
          <ButtonCTA onClick={() => sendMail()}>Submit</ButtonCTA>
        </OfficeRight>
      </OfficePart>
      {/* <MapImg src="/assets/image/map.png" alt="" draggable={false} /> */}
    </ComContainer>
  );
};

export default CompanyComponent;
