import React, { useState, useEffect } from "react";

// @import styles
import {
  HomeContainer,
  HomeMain,
  FaqPartOut,
  FaqPart,
  FaqSub,
  FaqTitle,
  FaqMain,
  FaqItem,
  FItemTop,
  FMainContent,
  FItemContent,
  FaqMainLeft,
  ProductPart,
  ProductTitle,
  ProductContent,
  ProductItems,
  ProductItem,
  ProductItemContent,
  LearnMoreBtn,
  CarPartOut,
  CarImg,
  CarPart,
  CarContent,
  CarBtns,
  CarLearnBtn,
  CarStartBrn,
  CarPartC,
  DashCamPart,
  DashContent,
  DashTitle,
  DashSub,
  Demo,
  LogPartOut,
  LogContent,
  LogPart,
  LogSub,
  LogTitle,
  LogImgTag,
  LogImgPartOut,
  LogImgRight,
  LogImgItem,
  LogCircle,
  LogCircleContent,
  LogmarginDiv,
  TrackPart,
  TrackMain,
  TrackLeft,
  TrackRight,
  TrackTitle,
  FleetPart,
  FleetLeft,
  FTitle,
  FContent,
  FSubWrapper,
  FleetRight,
  TrackSubWrapper,
  TrackContentWrapper,
  CarTitle,
} from "./dash.style";
import { BsArrowRight } from "react-icons/bs";
import { StrapiAPI } from "../../actions/config";
import axios from "axios";
import { Link } from "react-router-dom";
// @import resource

const ELDComponent = () => {
  const [selectFAQ, setSelectFAQ] = useState(-1);
  const [tselect, setTSelect] = useState(0);
  const [rselect, setRSelect] = useState(0);
  const [strapiData, setStrapiData] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const [homeData, setHomeData] = useState([]);
  const [offerData, setOfferData] = useState();
  const [boardData, setboardData] = useState();

  const fetchData = () => {
    axios.get(`${StrapiAPI}/dashcam-products`).then((awako) => {
      if (awako) {
        setStrapiData(awako?.data);
        setOfferData([
          StrapiAPI +
            awako?.data.filter((e) => e.name == "offer-1")[0]?.media?.url,
          StrapiAPI +
            awako?.data.filter((e) => e.name == "offer-2")[0]?.media?.url,
          StrapiAPI +
            awako?.data.filter((e) => e.name == "offer-3")[0]?.media?.url,
          StrapiAPI +
            awako?.data.filter((e) => e.name == "offer-4")[0]?.media?.url,
        ]);
        setboardData([
          StrapiAPI +
            awako?.data.filter((e) => e.name == "onboard-1")[0]?.media?.url,
          StrapiAPI +
            awako?.data.filter((e) => e.name == "onboard-2")[0]?.media?.url,
          StrapiAPI +
            awako?.data.filter((e) => e.name == "onboard-3")[0]?.media?.url,
          StrapiAPI +
            awako?.data.filter((e) => e.name == "onboard-4")[0]?.media?.url,
        ]);
      }
    });
    axios.get(`${StrapiAPI}/commons`).then((awako) => {
      if (awako) {
        setCommonData(awako?.data);
      }
    });
    axios.get(`${StrapiAPI}/homes`).then((awako) => {
      if (awako) {
        setHomeData(awako?.data);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const changeTSelect = (e) => {
    setTSelect(e);
  };
  const changeRSelect = (e) => {
    setRSelect(e);
  };
  return (
    <HomeContainer>
      <HomeMain>
        <TrackPart>
          <TrackMain
            bg={
              strapiData &&
              StrapiAPI +
                strapiData.filter((e) => e.name == "dashcam-land")[0]?.media
                  ?.url
            }
          >
            <TrackLeft>
              <TrackSubWrapper>DASHCAM</TrackSubWrapper>
              <TrackTitle>
                Ensuring <span>Safety</span> in a Dynamic Environment
              </TrackTitle>
              <TrackContentWrapper>
                High-quality onboard dash cameras provides exceptional video
                recordings of any incidents that occur on the road.
              </TrackContentWrapper>
            </TrackLeft>
          </TrackMain>
          {/* <TrackRight
            src={"/assets/image/eld-land.png"}
            alt=""
            draggable={false}
          /> */}
        </TrackPart>
        <LogPartOut>
          <LogPart>
            <LogSub>Our Features</LogSub>
            <LogTitle>Dash Cam Data Helps You Avoid Potential Dangers</LogTitle>
            <LogContent>
              Additionally, Awako utilizes vehicle telematics which allows the
              system to use input from the dash cams to provide a variety of
              safety and economic data.
            </LogContent>
            <LogImgPartOut>
              <LogImgTag>
                <img
                  src={offerData && offerData[rselect]}
                  alt=""
                  draggable={false}
                />
              </LogImgTag>
              <LogImgRight>
                <LogImgItem
                  select={rselect == 0 && true}
                  onClick={() => changeRSelect(0)}
                >
                  <LogCircle>
                    <img src="/assets/image/tmsSteps/ico1.png" alt="" />
                  </LogCircle>
                  <LogCircleContent f={rselect == 0 && true}>
                    AI powered fleet safety solution
                  </LogCircleContent>
                </LogImgItem>
                <LogImgItem
                  select={rselect == 1 && true}
                  onClick={() => changeRSelect(1)}
                >
                  <LogCircle>
                    <img src="/assets/image/eldIco/ear.png" alt="" />
                  </LogCircle>
                  <LogCircleContent f={rselect == 1 && true}>
                    In cab driver assistance
                  </LogCircleContent>
                </LogImgItem>
                <LogImgItem
                  select={rselect == 2 && true}
                  onClick={() => changeRSelect(2)}
                >
                  <LogCircle>
                    <img src="/assets/image/eldIco/video.png" alt="" />
                  </LogCircle>
                  <LogCircleContent f={rselect == 2 && true}>
                    Video telematics and live streaming
                  </LogCircleContent>
                </LogImgItem>
                <LogImgItem
                  select={rselect == 3 && true}
                  onClick={() => changeRSelect(3)}
                >
                  <LogCircle>
                    <img src="/assets/image/eldIco/warn.png" alt="" />
                  </LogCircle>
                  <LogCircleContent f={tselect == 3 && true}>
                    Mitigate accident risks
                  </LogCircleContent>
                </LogImgItem>
              </LogImgRight>
            </LogImgPartOut>
          </LogPart>
          <LogmarginDiv />
          <LogPart>
            <LogSub>Our Features</LogSub>
            <LogTitle>
              High-definition <span>AI Dash Cam</span> with Video Telematics
            </LogTitle>
            <LogContent>
              Automated Alerts and High-Quality Video Footage to Keep Everyone
              Aware and Protected on the Road
            </LogContent>
            <LogImgPartOut>
              <LogImgRight f={true}>
                <LogImgItem
                  select={tselect == 0 && true}
                  onClick={() => changeTSelect(0)}
                >
                  <LogCircle>
                    <img src="/assets/image/eldIco/img.png" alt="" />
                  </LogCircle>
                  <LogCircleContent f={tselect == 0 && true}>
                    DVR and time-lapse footage requests
                  </LogCircleContent>
                </LogImgItem>
                <LogImgItem
                  select={tselect == 1 && true}
                  onClick={() => changeTSelect(1)}
                >
                  <LogCircle>
                    <img src="/assets/image/eldIco/dorure.png" alt="" />
                  </LogCircle>
                  <LogCircleContent f={tselect == 1 && true}>
                    Driver coaching workflow
                  </LogCircleContent>
                </LogImgItem>
                <LogImgItem
                  select={tselect == 2 && true}
                  onClick={() => changeTSelect(2)}
                >
                  <LogCircle>
                    <img src="/assets/image/eldIco/contact.png" alt="" />
                  </LogCircle>
                  <LogCircleContent f={tselect == 2 && true}>
                    Driver scorecard reporting and compliance trends
                  </LogCircleContent>
                </LogImgItem>
                <LogImgItem
                  select={tselect == 3 && true}
                  onClick={() => changeTSelect(3)}
                >
                  <LogCircle>
                    <img src="/assets/image/eldIco/ai.png" alt="" />
                  </LogCircle>
                  <LogCircleContent f={tselect == 3 && true}>
                    Positive driver recognition
                  </LogCircleContent>
                </LogImgItem>
              </LogImgRight>
              <LogImgTag f={true}>
                <img
                  src={boardData && boardData[tselect]}
                  alt=""
                  draggable={false}
                />
              </LogImgTag>
            </LogImgPartOut>
          </LogPart>
        </LogPartOut>
        <FaqPartOut>
          <ProductPart>
            <ProductTitle>
              Product <span>Snippets</span>
            </ProductTitle>
            <ProductContent>
              Our Powerful Solutions to Help You Manage Your Fleet
            </ProductContent>
            <ProductItems>
              <ProductItem>
                <img
                  src={
                    homeData &&
                    StrapiAPI +
                      homeData.filter((e) => e.name == "tms")[0]?.media?.url
                  }
                  alt=""
                />
                <ProductItemContent>
                  <span>TMS</span>An all-in-one platform powered by AI that
                  helps you manage your all of your business in one
                  user-friendly and powerful solution.
                  <Link to="/tms">
                  <LearnMoreBtn>
                    Learn more <BsArrowRight />
                  </LearnMoreBtn>
                  </Link>
                </ProductItemContent>
              </ProductItem>
              <ProductItem>
                <img
                  src={
                    homeData &&
                    StrapiAPI +
                      homeData.filter((e) => e.name == "eld")[0]?.media?.url
                  }
                  alt=""
                />
                <ProductItemContent>
                  <span>ELD</span>Advanced electronic logging devices  to help
                  automate and ensure compliance with  FMCSA and DOT
                  regulations. Available in both Bluetooth and Hard-wired
                  versions.
                  <Link to="/eld">
                  <LearnMoreBtn>
                    Learn more <BsArrowRight />
                  </LearnMoreBtn>
                  </Link>
                </ProductItemContent>
              </ProductItem>
              <ProductItem>
                <img
                  src={
                    homeData &&
                    StrapiAPI +
                      homeData.filter((e) => e.name == "dashcam")[0]?.media?.url
                  }
                  alt=""
                />
                <ProductItemContent>
                  <span>DashCam</span>High-quality onboard dash cameras provides
                  exceptional video recording and utilizes vehicle telematics to
                  provide a variety of safety and economic data and real-time
                  alerts to both drivers and management.
                  <Link to="/dashcams">
                  <LearnMoreBtn>
                    Learn more <BsArrowRight />
                  </LearnMoreBtn>
                  </Link>
                </ProductItemContent>
              </ProductItem>
            </ProductItems>
          </ProductPart>
        </FaqPartOut>
        <CarPartOut>
          <CarPartC>
            <CarImg
              src={
                commonData &&
                StrapiAPI +
                  commonData.filter((e) => e.name == "trans")[0]?.media?.url
              }
              alt=""
              draggable={false}
            />
            <CarPart>
              <CarTitle>
                Taking Your Fleet Into the <span>Future</span>
              </CarTitle>
              <CarContent>
                Transition your business to experience the true power of a
                single platform to manage your entire operation and let Awako
                take your company into the future of fleet management.
              </CarContent>
              {/* <CarBtns>
                <CarLearnBtn>Learn more</CarLearnBtn>
                <CarStartBrn>
                  Get started now <BsArrowRight className="car-start-btn" />
                </CarStartBrn>
              </CarBtns> */}
            </CarPart>
          </CarPartC>
        </CarPartOut>
      </HomeMain>
    </HomeContainer>
  );
};

export default ELDComponent;
