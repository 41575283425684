import styled from "styled-components";

export const ComContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AwakoTitle = styled.div`
  margin: 0 0 72px;
  font-family: JakartaBold;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  @media screen and (max-width: 500px) {
    font-size: 25px;
  }
  @media screen and (max-width: 720px) {
    width: 90%;
    line-height: 30px;
  }
  @media screen and (max-width: 450px) {
    font-size: 25px;
  }
`;
export const DediPartOut = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 107px;
  @media screen and (max-width: 900px) {
    margin-top: 0px;
  }
`;
export const DediPart = styled.div`
  max-width: 1440px;
  display: flex;
  margin-bottom: 131px;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 1450px) {
    justify-content: center;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
export const DediLeft = styled.div`
  background: #d9d9d9;
  border-radius: 16px;
  width: 583px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 160px;
  background: url(${(props) => props.f});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1450px) {
    margin-left: 0;
  }
  @media screen and (max-width: 1100px) {
    width: 400px;
    height: 400px;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
    height: 250px;
  }
`;
export const DediRight = styled.div`
  width: 399px;
  margin-left: 57px;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-left: 0;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`;
export const DediTitle = styled.div`
  font-size: 32px;
  line-height: 125%;
  font-family: JakartaBold;
  text-align: left;
  color: #1d1d1d;
  span {
    color: #005dfe;
  }
  @media screen and (max-width: 900px) {
    text-align: center;
  }
  @media screen and (max-width: 450px) {
    font-size: 25px;
  }
`;
export const DediContent = styled.div`
  margin: 24px 0 40px;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 200%;
  color: #4d4d4d;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
  @media screen and (max-width: 450px) {
    margin: 15px 0 20px;
  }
`;
export const OfficePart = styled.div`
  display: flex;
  margin-bottom: 80px;
  @media screen and (max-width: 780px) {
    flex-direction: column;
    width: 90%;
  }
`;
export const OfficeLeft = styled.div``;
export const OfficeText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 200%;
  width: 313px;
  color: #4d4d4d;
  text-align: left;
  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;
export const Off = styled.div`
  margin: 45px 0 35px;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: #1d1d1d;
  text-align: left;
  @media screen and (max-width: 500px) {
    font-size: 25px;
  }
`;
export const Info = styled.div`
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 225%;
    color: #4d4d4d;
    text-align: left;
  }
`;
export const Socials = styled.div`
  display: flex;
  gap: 24px;
`;
export const SItem = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #e4e7ec;
`;
export const OfficeRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 479px;
  @media screen and (max-width: 950px) {
    width: 300px;
  }
  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;
export const OfficeMiddle = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 465px;
  margin: 0 120px;
  @media screen and (max-width: 1100px) {
    margin: 0 50px;
  }
  @media screen and (max-width: 780px) {
    height: 0;
    width: 100%;
    margin: 40px 0;
  }
`;
export const CusNameInput = styled.input`
  font-weight: 400;
  font-size: 14px;
  line-height: 225%;
  color: #4d4d4d;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 32px;
  background: #ffffff;
  outline: none;
  padding: 10px 14px;
  border: 1px solid #d0d5dd;
`;
export const CusTextArea = styled.textarea`
  font-weight: 400;
  outline: none;
  font-size: 14px;
  line-height: 225%;
  padding: 10px 14px;
  border: 1px solid #d0d5dd;
  color: #4d4d4d;
  height: 128px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
`;
export const ButtonCTA = styled.div`
  height: 53px;
  background: #005dfe;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1em;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  cursor: pointer;
  color: #ffffff;
`;
export const DediSubT = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  text-align: left;
  margin-bottom: 24px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #005dfe;
`;
