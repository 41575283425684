import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #0d0d0d;
`;

export const HeaderMain = styled.div`
  max-width: 1440px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 64px 0;
`;

export const LogoPart = styled.div`
  margin-left: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 520px;
  span {
    font-size: 12px;
    margin-top: 24px;
    text-align: left;
    line-height: 175%;
    letter-spacing: 0.1em;
    color: rgba(255, 255, 255, 0.8);
  }
  @media screen and (max-width: 1350px) {
    max-width: 370px;
  }
  @media screen and (max-width: 1250px) {
    margin-left: 50px;
  }
  @media screen and (max-width: 1000px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 900px) {
    max-width: 300px;
  }
  @media screen and (max-width: 820px) {
    max-width: 250px;
  }
  @media screen and (max-width: 750px) {
    margin-left: 0;
    max-width: 410px;
  }
  @media screen and (max-width: 470px) {
    width: 90%;
  }
`;
export const LogoImg = styled.img`
  @media screen and (max-width: 800px) {
    width: 150px;
  }
`;
export const ContactBtn = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 40px;
`;

export const FooterTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
`;
export const ProductPart = styled.div`
  display: flex;
  gap: 90px;
  /* margin-right: 120px; */
  @media screen and (max-width: 1500px) {
    margin-right: 80px;
  }
  @media screen and (max-width: 1350px) {
    gap: 70px;
  }

  @media screen and (max-width: 1000px) {
    margin-right: 30px;
    gap: 40px;
  }
  @media screen and (max-width: 750px) {
    margin-right: 0;
    gap: 80px;
  }
  @media screen and (max-width: 470px) {
    flex-direction: column;
    width: 90%;
    gap: 40px;
  }
`;
export const PItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 470px) {
    width: 100%;
  }
`;
export const Ptitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 175%;
  color: #ffffff;
  opacity: 0.8;
`;

export const PSubItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 40px;
  gap: 8px;
  span {
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    line-height: 225%;
    color: rgba(255, 255, 255, 0.8);
  }
  @media screen and (max-width: 470px) {
    margin-top: 20px;
  }
`;
